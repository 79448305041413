import * as React from "react";
import { Collapse } from "antd";
import { CollapseProps } from "antd/lib";

type SCCollapseProps = {
  collapseProps: CollapseProps;
  panelStyle: React.CSSProperties;
  collapseItems: CollapseProps["items"];
};

const SCCollapse: React.FC<SCCollapseProps> = (props: SCCollapseProps) => {
  const { collapseItems, panelStyle } = props;
  let styledCollapseItems = collapseItems?.map((v) => {
    v.style = panelStyle;
    return v;
  });

  return <Collapse items={styledCollapseItems} {...props.collapseProps} />;
};

export default SCCollapse;
