export const MORE_MENU_OPTIONS = {
  DASHBOARD_REPORTS: "Reports",
  COMPANY_PROFILE: "Company Profile",
  WORKFORCE_PROFILE: "Workforce Profile",
  THEME: "Theme",
  MANAGE_TEAMS: "Manage Teams",
  COURSES_CONFIG: "Courses Configuration (WIP)",
  ORGANIZATION_STRUCTURE_CONFIG: "Organization Structure Configuration (WIP)",
  EMPLOYMENT_EQUITY_PORTAL: "Employment Equity Portal",
  BILLING_INFORMATION: "Billing Information (WIP)",
  SKILLS_MANAGEMENT: "Skills Management (WIP)",
  PERFORMANCE_MANAGEMENT: "Performance Management (WIP)",
  DOWNLOAD_APP: "Download App",
};

export const TOP_RIGHT_MENU_OPTIONS = {
  MY_SETTINGS: "My Settings",
  PURCHASE_HISTORY: "Purchase History",
  HELP_AND_SUPPORT: "Help and Support",
  LOGOUT: "Log Out",
};

export const MAIN_MENU_OPTIONS = {
  DASHBOARD: "Dashboard",
  MORE: "More",
  MY_PROFILE: "My Profile",
  MY_CV: "My CV",
  MY_COURSES: "My Courses",
  STORE: "Store",
  LIBRARY: "Course Library",
  PROFILE: "Profile",
  TEAMS: "My Teams",
};
