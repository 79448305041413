import { TablePaginationConfig, TableProps } from "antd";
import { SorterResult } from "antd/es/table/interface";
import { GetProp } from "antd/lib";

import { WorkForceTableDataType } from "../Presentation/WorkForceProfile/Table/WorkforceTable";

export const FieldValidations = {
  NAME_MIN: 2,
  NAME_MAX: 20,
  SURNAME_MIN: 2,
  SURNAME_MAX: 20,
  EMPLOYEE_NUMBER_MIN: 1,
  EMPLOYEE_NUMBER_MAX: 20,
  EMAIL_MIN: 6,
  EMAIL_MAX: 60,
  MOBILE_MIN: 10,
  MOBILE_MAX: 20,
  ID_NUMBER_MIN: 13,
  ID_NUMBER_MAX: 13,
  PASSPORT_MIN: 5,
  AGE_MIN: 1,
  AGE_MAX: 2,
  BRANCH_MIN: 2,
  BRANCH_MAX: 30,
  BUSINESS_UNIT_MIN: 2,
  BUSINESS_UNIT_MAX: 30,
  DEPARTMENT_MIN: 2,
  DEPARTMENT_MAX: 30,
  JOB_TITLE_MIN: 2,
  JOB_TITLE_MAX: 30,
  OFO_CODE_MIN: 2,
  OFO_CODE_MAX: 10,
};

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(.+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const WorkForceProfileTableFilterFields: Partial<
  Record<keyof WorkForceTableDataType, string>
> = {
  employeeFirstName: "Employee First Name",
  employeeSurname: "Employee Surname",
  employeeNumber: "Employee Number",
  emailAddress: "Email Address",
  idNumber: "ID Number",
  passportNumber: "Passport number",
  foreignNational: "Foreign National",
  streetAddress: "Street Address",
  suburb: "Suburb",
  city: "City",
  provinceId: "Province/State",
  countryName: "Country",
  postalCode: "Postal Code",
  branch: "Branch",
  department: "Department",
  businessUnit: "Business Unit",
  jobTitle: "Job Title",
  employmentStatus: "Employment Status",
  entityName: "Entity",
  userType: "User Type",
};

export const DEFAULT_CHECKED: Partial<keyof WorkForceTableDataType>[] = [
  "employeeFirstName",
  "employeeSurname",
  "emailAddress",
  "employeeNumber",
];

export interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: SorterResult<WorkForceTableDataType>["field"];
  sortOrder?: SorterResult<WorkForceTableDataType>["order"];
  filters?: Parameters<GetProp<TableProps, "onChange">>[1];
}

export const WFP_RECORDS_PER_PAGE = 50;

export const TEXT_INPUT_REG = new RegExp(/^[a-zA-Z0-9_ ]*$/);

export const WFP_DOWNLOAD_FILENAME = "Workforce_Basic_Import.xlsx";
