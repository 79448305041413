import { API_URLS } from "../../../../../../core/models/enums/url.enums";
import axiosHttp from "../../../../../../core/services/Interceptors";
import { GenericResponse } from "../../../../../Teams/service/teamsServices";
import {
  GetOccupationLevelStrategyAnalysisRes,
  IAnalysisStrategies,
} from "./type.service";

export type PostWorkforceAnalysisRequestBodyPayload = {
  entityWorkForceAnalysisKey?: string;
  categoryIds: string;
  barrierNaritiveIds: string;
  affarmativeActionMeasureIds: string;
  policy: boolean;
  practice: boolean;
  procedures: boolean;
  barrierStartDate: string;
  barrierEndDate: string;
  jobTitleId: number;
};

export type PostWorkforceAnalysisPayload = {
  requestBody: PostWorkforceAnalysisRequestBodyPayload;
  entityId: string;
};

export const postWorkforceAnalysis = async (
  params: PostWorkforceAnalysisPayload
) => {
  if (!params.requestBody.entityWorkForceAnalysisKey) {
    params.requestBody.entityWorkForceAnalysisKey =
      "00000000-0000-0000-0000-000000000000";
  }

  const url = `${API_URLS.SUBMIT_ANALYSIS.replace("{id}", params.entityId ?? "")}`;

  const response = await axiosHttp.post<GenericResponse<void>>(
    url,
    params.requestBody
  );

  return response.data;
};

export type GetWorkforceAnalysisPayload = {
  entityRecordKey: string;
};

export type GetWorkforceAnalysisResponse = {
  entityWorkForceAnalysisId: number;
  entityWorkForceAnalysisKey: string;
  categoryIds: string;
  barrierNaritiveIds: string;
  affarmativeActionMeasureIds: string;
  policy: true;
  practice: true;
  procedures: true;
  barrierStartDate: string;
  barrierEndDate: string;
  jobTitleId: number;
  eePlanStartDate: string;
  eePlanEndDate: string;
};

export const getSubmittedWABarriersData = async (
  params: GetWorkforceAnalysisPayload
) => {
  const url = `${API_URLS.GET_WORKFORCE_ANALYSIS.replace("{id}", params.entityRecordKey ?? "")}`;

  const response =
    await axiosHttp.get<GenericResponse<GetWorkforceAnalysisResponse>>(url);
  return response.data;
};

export type GetBarriersResponse = {
  barrierId: number;
  barrierCategory: string;
  barrierCategoryParentId: number;
  barrierNarrativeParentId: number;
  barrierNarrative: string;
  affirmativeActionMeasure: string;
  // following are only for FE use
  isCategory: boolean;
  isBarrier: boolean;
  isMeasure: boolean;
  timeStampEpoch: number;
  isTicked: boolean;
  isSelected: boolean;
  content: string;

  barrierList: GetBarriersResponse[];
  measureList: GetBarriersResponse[];
};

export const fetchBarriers = async () => {
  const response = await axiosHttp.get<GenericResponse<GetBarriersResponse[]>>(
    API_URLS.GET_BARRIERS
  );
  return response.data;
};

export type GetStrategyNarretivePayload = {
  entityRecordKey: string;
};

export type strategyNarrativeListResponse = {
  strategyId: number;
  strategyNarrative: string;

  // following are only for FE use
  timeStampEpoch: number;
  isTicked: boolean;
  isSelected: boolean;
  content: string;
};

export const fetchStrategyNarrative = async (
  params: GetStrategyNarretivePayload
) => {
  const response = await axiosHttp.get<
    GenericResponse<strategyNarrativeListResponse[]>
  >(API_URLS.GET_STRATEGY_NARRATIVE);

  return response.data;
};

export type GetStrategyNarretiveActionsPayload = {
  entityRecordKey: string;
};

export type strategyNarretiveActionsResponse = {
  strategyActionExampleId: number;
  strategyActionExampleText: string;
  strategyId: number;
  // following are only for FE use
  timeStampEpoch: number;
  isTicked: boolean;
  isSelected: boolean;
  content: string;
};

export const fetchStrategyNarrativeActionExamples = async (
  params: GetStrategyNarretiveActionsPayload
) => {
  const response = await axiosHttp.get<
    GenericResponse<strategyNarretiveActionsResponse[]>
  >(API_URLS.GET_STRATEGY_NARRATIVE_ACTION_EXAMPLES);

  return response.data;
};

export type GetOccupationLevelStrategyAnalysisPayload = {
  entityRecordKey: string;
};

export const GetOccupationLevelStrategyAnalysis = async (
  parmas: GetOccupationLevelStrategyAnalysisPayload
) => {
  const url = `${API_URLS.GET_OCCUPATION_LEVEL_STRATEGY_ANALYSIS.replace("{id}", parmas.entityRecordKey ?? "")}`;

  const response =
    await axiosHttp.get<
      GenericResponse<GetOccupationLevelStrategyAnalysisRes[]>
    >(url);

  return response.data;
};

export type postWorkforceAnalysisStrategySelectionPayloadBody = {
  workforceAnalysisStrategiesCreateDtos: IAnalysisStrategies[];
  eapMasterId: number;
  occupationId: number;
  snapshotDate: string;
  isStrategyApplied: boolean;
  strategyNarratives: string;
  strategyNarrativesAction: string;
  entityWorkForceAnalysisStrategiesSelectionKey: string;
  entityId?: string;
};

export type postWorkforceAnalysisStrategySelectionPayload = {
  postWorkforceAnalysisStrategySelectionPayloadBody: postWorkforceAnalysisStrategySelectionPayloadBody[];
  entityId?: string;
};

export const postWorkforceAnalysisStrategySelection = async (
  params: postWorkforceAnalysisStrategySelectionPayload
) => {
  const url = `${API_URLS.SAVE_STRATEGY_SELECTION.replace("{id}", params.entityId ?? "")}`;

  const response = await axiosHttp.post<GenericResponse<void>>(
    url,
    params.postWorkforceAnalysisStrategySelectionPayloadBody
  );

  return response.data;
};

export type FetchReportAnalysisPayload = {
  entityId: string;
};

export type FetchReportAnalysisResponse = {
  tradeName: string;
  dtiRegistrationNumber: string;
  paysrsNumber: string;
  uifReferenceNumber: string;
  email: string;
  telephoneNumber: string;
  setaClassification: string;
  industrySubSector: string;
  physicalProvince: string;
  postalAddress: string;
  physicalAddress: string;
  postalCode: string;
  ceoName: string;
  reportText: string;
};

export const fetchReportAnalysis = async (
  params: FetchReportAnalysisPayload
) => {
  const url = `${API_URLS.GET_REPORT_ANALYSIS.replace("{id}", params.entityId ?? "")}`;

  const response =
    await axiosHttp.get<GenericResponse<FetchReportAnalysisResponse>>(url);

  return response.data;
};
