import { Table } from "antd";
import type { TableColumnsType } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import {
  DataType,
  EthnicityType,
  EthnicityTypeEnum,
  GenderType,
  GenderTypeEnum,
  renderDataWithCondition,
} from "../../../../../helpers/helper";

const LevelAnalysisTable: React.FC<{
  actualDetailedData: DataType[];
  isLoading: boolean;
}> = ({ actualDetailedData, isLoading }) => {
  const { t } = useTranslation();
  const columns: TableColumnsType<DataType> = [
    {
      title: "",
      align: "center",
      dataIndex: "name",
      key: "name",
      width: 500,
    },
    {
      title: "Male",
      align: "center",
      children: [
        {
          title: "A",
          align: "center",
          dataIndex: "male",
          key: "male",
          render: (val: EthnicityType) =>
            renderDataWithCondition(val, EthnicityTypeEnum.african, isLoading),
        },
        {
          title: "C",
          align: "center",
          dataIndex: "male",
          key: "male",
          render: (val: EthnicityType) =>
            renderDataWithCondition(val, EthnicityTypeEnum.colored, isLoading),
        },
        {
          title: "I",
          align: "center",
          dataIndex: "male",
          key: "male",
          render: (val: EthnicityType) =>
            renderDataWithCondition(val, EthnicityTypeEnum.Indian, isLoading),
        },
        {
          title: "W",
          align: "center",
          dataIndex: "male",
          key: "male",
          render: (val: EthnicityType) =>
            renderDataWithCondition(val, EthnicityTypeEnum.white, isLoading),
        },
      ],
    },
    {
      title: "Female",
      align: "center",
      children: [
        {
          title: "A",
          align: "center",
          dataIndex: "female",
          key: "female",
          render: (val: EthnicityType) =>
            renderDataWithCondition(val, EthnicityTypeEnum.african, isLoading),
        },
        {
          title: "C",
          align: "center",
          dataIndex: "female",
          key: "female",
          render: (val: EthnicityType) =>
            renderDataWithCondition(val, EthnicityTypeEnum.colored, isLoading),
        },
        {
          title: "I",
          align: "center",
          dataIndex: "female",
          key: "female",
          render: (val: EthnicityType) =>
            renderDataWithCondition(val, EthnicityTypeEnum.Indian, isLoading),
        },
        {
          title: "W",
          align: "center",
          dataIndex: "female",
          key: "female",
          render: (val: EthnicityType) =>
            renderDataWithCondition(val, EthnicityTypeEnum.white, isLoading),
        },
      ],
    },
    {
      title: "Foreign Nationals",
      align: "center",
      children: [
        {
          title: "Male",
          align: "center",
          dataIndex: "foreignNational",
          key: "foreignNational",
          render: (val: GenderType) =>
            renderDataWithCondition(val, GenderTypeEnum.male, isLoading),
        },
        {
          title: "Female",
          align: "center",
          dataIndex: "foreignNational",
          key: "foreignNational",
          render: (val: GenderType) =>
            renderDataWithCondition(val, GenderTypeEnum.female, isLoading),
        },
      ],
    },
    {
      title: "Total",
      align: "center",
      dataIndex: "total",
      key: "total",
      render: (val, ogVal) =>
        renderDataWithCondition(
          { total: val },
          EthnicityTypeEnum.total,
          isLoading
        ),
    },
  ];
  return (
    <Table<DataType>
      columns={columns}
      dataSource={actualDetailedData}
      bordered
      size="middle"
      rowClassName="text-center"
      pagination={false}
      loading={isLoading}
      scroll={{
        x: 1000,
      }}
      footer={() => (
        <tr>
          <td colSpan={11} className="font-semibold">
            {t("wfa.OLA.note")}
          </td>
        </tr>
      )}
    />
  );
};

export default LevelAnalysisTable;
