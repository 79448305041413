import * as React from "react";
import { FC } from "react";
import { FaCheck } from "react-icons/fa";

import { GetBarriersResponse } from "../../services/wfa.services";

type SelectionBoxMobileProps = {
  item: GetBarriersResponse;
};

const SelectionBoxMobile: FC<SelectionBoxMobileProps> = (
  props: SelectionBoxMobileProps
) => {
  const { item } = props;
  return (
    <div
      className={`relative py-[20px] pl-[30px] font-secondary text-sm font-semibold text-lightBlack md:text-lg 2xl:text-xl ${item.isTicked || item.isSelected ? "bg-bgLightBlue" : ""}`}
    >
      {item.isTicked && (
        <FaCheck
          className="absolute left-[10px] top-[23px]"
          size={15}
          color="var(--primary)"
        />
      )}
      {item?.content}
    </div>
  );
};

export default SelectionBoxMobile;
