import { useMemo } from "react";

import { DataType, ItemHelper, assignPriorities } from "../helpers/helper";
import {
  IAnalysisStrategies,
  IEntityEAPMasterDtos,
  neededDataInPriority,
} from "../services/type.service";

const useTransformOccupationLevelStrategyAnalysis = ({
  analysisData,
  masterData,
}: {
  analysisData?: IAnalysisStrategies[];
  masterData?: IEntityEAPMasterDtos;
}) => {
  const { calculatedPercentage, actualData, overUnderRepresentedCount } =
    useMemo(() => {
      if (!analysisData?.length) {
        return {
          calculatedPercentage: {} as IAnalysisStrategies,
          actualData: {} as IAnalysisStrategies,
          overUnderRepresentedCount: {} as IAnalysisStrategies,
        };
      }

      const calculatedPercentage =
        analysisData.find(
          ({ isCalculatedPercentage }) => isCalculatedPercentage
        ) || ({} as IAnalysisStrategies);

      const actualData =
        analysisData.find(({ isActual }) => isActual) ||
        ({} as IAnalysisStrategies);

      const overUnderRepresentedCount =
        analysisData.find(
          ({ isOverUnderRepresentedCount }) => isOverUnderRepresentedCount
        ) || ({} as IAnalysisStrategies);

      return { calculatedPercentage, actualData, overUnderRepresentedCount };
    }, [analysisData]);

  const calculatedPriority = assignPriorities(
    Object.entries(calculatedPercentage)
      .map(([name, val]) => {
        if (neededDataInPriority.includes(name)) {
          return { name, val: parseFloat(`${val}`) }; // Return the object if the condition is met
        }
        return undefined; // Return undefined otherwise
      })
      .filter((val): val is ItemHelper => val !== undefined)
  ); // Filter out undefined values

  const priorityData = useMemo(
    () => ({
      africanMale: calculatedPriority?.africanMale,
      coloredMale: calculatedPriority?.coloredMale,
      indianMale: calculatedPriority?.indianMale,
      whiteMale: calculatedPriority?.whiteMale,
      africanFemale: calculatedPriority?.africanFemale,
      coloredFemale: calculatedPriority?.coloredFemale,
      indianFemale: calculatedPriority?.indianFemale,
      whiteFemale: calculatedPriority?.whiteFemale,
    }),
    [calculatedPriority]
  );

  // Step 1: Transform the priorityData object into an array of objects
  const transformedData = Object.entries(priorityData).map(([key, value]) => ({
    category: key,
    value,
    label: `${value}%`,
  }));

  // Step 2: Sort the array by the 'value' property in ascending order
  const sortedArray = transformedData.sort(
    (itemA, itemB) => itemA.value - itemB.value
  );

  // Assign priority, handling same values with the same priority
  let currentPriority = 1;
  const prioritizedArray = sortedArray
    .map((item, index, array) => {
      if (index > 0 && item.value !== array[index - 1].value) {
        currentPriority = currentPriority + 1;
      }

      return {
        // ...item,
        [item.category]: currentPriority,
      };
    })
    .reduce((acc, obj) => {
      return { ...acc, ...obj };
    }, {});

  const actualDetailedData: DataType[] = useMemo(() => {
    return [
      {
        key: 1,
        name: "EAP",
        male: {
          african: masterData?.africanMale,
          colored: masterData?.coloredMale,
          Indian: masterData?.indianMale,
          white: masterData?.whiteMale,
        },
        female: {
          african: masterData?.africanFemale,
          colored: masterData?.coloredFemale,
          Indian: masterData?.indianFemale,
          white: masterData?.whiteFemale,
        },
        foreignNational: {
          male: masterData?.foreignNationalMale,
          female: masterData?.foreignNationalFemale,
        },
        total: masterData?.total,
      },
      {
        key: 2,
        name: "Actual",
        male: {
          african: actualData?.africanMale,
          colored: actualData?.coloredMale,
          Indian: actualData?.indianMale,
          white: actualData?.whiteMale,
        },
        female: {
          african: actualData?.africanFemale,
          colored: actualData?.coloredFemale,
          Indian: actualData?.indianFemale,
          white: actualData?.whiteFemale,
        },
        foreignNational: {
          male: actualData?.foriegnNationalMale,
          female: actualData?.foriegnNationalFemale,
        },
        total: actualData?.total,
      },
      {
        key: 3,
        name: "%",
        male: {
          african: calculatedPercentage?.africanMale,
          colored: calculatedPercentage?.coloredMale,
          Indian: calculatedPercentage?.indianMale,
          white: calculatedPercentage?.whiteMale,
        },
        female: {
          african: calculatedPercentage?.africanFemale,
          colored: calculatedPercentage?.coloredFemale,
          Indian: calculatedPercentage?.indianFemale,
          white: calculatedPercentage?.whiteFemale,
        },
        foreignNational: {
          male: calculatedPercentage?.foriegnNationalMale,
          female: calculatedPercentage?.foriegnNationalFemale,
        },
        total: calculatedPercentage?.total,
      },
    ];
  }, [calculatedPercentage, actualData, masterData]);

  const findingDetailedData: DataType[] = useMemo(() => {
    return [
      {
        key: 1,
        name: "Over/Underachieved %",
        male: {
          african: calculatedPercentage?.africanMale,
          colored: calculatedPercentage?.coloredMale,
          Indian: calculatedPercentage?.indianMale,
          white: calculatedPercentage?.whiteMale,
        },
        female: {
          african: calculatedPercentage?.africanFemale,
          colored: calculatedPercentage?.coloredFemale,
          Indian: calculatedPercentage?.indianFemale,
          white: calculatedPercentage?.whiteFemale,
        },
        foreignNational: {
          male: calculatedPercentage?.foriegnNationalMale,
          female: calculatedPercentage?.foriegnNationalFemale,
        },
        total: calculatedPercentage?.total,
      },
      {
        key: 2,
        name: "Over/Underachieved headcount",
        male: {
          african: overUnderRepresentedCount?.africanMale,
          colored: overUnderRepresentedCount?.coloredMale,
          Indian: overUnderRepresentedCount?.indianMale,
          white: overUnderRepresentedCount?.whiteMale,
        },
        female: {
          african: overUnderRepresentedCount?.africanFemale,
          colored: overUnderRepresentedCount?.coloredFemale,
          Indian: overUnderRepresentedCount?.indianFemale,
          white: overUnderRepresentedCount?.whiteFemale,
        },
        foreignNational: {
          male: overUnderRepresentedCount?.foriegnNationalMale,
          female: overUnderRepresentedCount?.foriegnNationalFemale,
        },
        total: overUnderRepresentedCount?.total,
      },
      {
        key: 3,
        name: "Priority",
        male: {
          african: prioritizedArray?.africanMale,
          colored: prioritizedArray?.coloredMale,
          Indian: prioritizedArray?.indianMale,
          white: prioritizedArray?.whiteMale,
        },
        female: {
          african: prioritizedArray?.africanFemale,
          colored: prioritizedArray?.coloredFemale,
          Indian: prioritizedArray?.indianFemale,
          white: prioritizedArray?.whiteFemale,
        },
        foreignNational: {
          male: 0.0,
          female: 0.0,
        },
        total: 0.0,
      },
    ];
  }, [overUnderRepresentedCount, prioritizedArray, calculatedPercentage]);

  return {
    actualDetailedData,
    findingDetailedData,
  };
};

export default useTransformOccupationLevelStrategyAnalysis;
