import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { App } from "antd";
import { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

import router from "./Routes";
import i18n from "./i18next";
import "./index.css";
import { AuthServiceProvider } from "./modules/auth/services/Auth.services";
import Loader from "./modules/core/presentation/Loader";
import { APIServiceProvider } from "./modules/core/services/API.service";
import reportWebVitals from "./reportWebVitals";
import { persistor, store } from "./store";
import "./styles/antd-custom.css";
import "./styles/responsive.css";
import "./styles/style.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const queryClient = new QueryClient();

root.render(
  <Suspense fallback={<Loader styleLoadingOutlined={{ fontSize: 100 }} />}>
    <Provider store={store}>
      <App>
        <PersistGate loading={null} persistor={persistor}>
          <I18nextProvider i18n={i18n} defaultNS={"locale"}>
            <QueryClientProvider client={queryClient}>
              <ReactQueryDevtools initialIsOpen={false} />
              <APIServiceProvider>
                <AuthServiceProvider>
                  <RouterProvider router={router} />
                </AuthServiceProvider>
              </APIServiceProvider>
            </QueryClientProvider>
          </I18nextProvider>
        </PersistGate>
      </App>
    </Provider>
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
