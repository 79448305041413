import { Col, Flex, Row, Typography } from "antd";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { FaCheck } from "react-icons/fa";

import EEYellowTickBanner from "../../../../../../../core/component/ui/EEYellowTickBanner/EEYellowTickBanner";
import SCButton from "../../../../../../../core/component/ui/SCButton";
import { SC_BUTTON_TYPE } from "../../../../../../../core/models/constants/core.constants";
import { SkeletonCommon } from "../../containers/WorkforceAnalysisContainer/WorkforceAnalysisContainer";
import useBarrierActions from "../../hooks/useBarrierActions";
import { GetBarriersResponse } from "../../services/wfa.services";
import BarrierConfirmationForm from "../BarrierConfirmationForm/BarrierConfirmationForm";
import styles from "../SCCheckboxButton/SCCheckboxButton.module.css";
import SCCheckboxButtonHeading from "../SCCheckboxButton/SCCheckboxButtonHeading";

const EEBarrierActions = ({ next, prev }: any) => {
  const { t } = useTranslation();

  const {
    isLoading,
    submittedWABarriersData,
    isWABarrierSubmitted,
    showConfirmbtn,
    setSelectedItem,
    categoryBarriersAndActionData,
    barrierData,
    measureData,
    showConfirmationForm,
    setShowConfirmationForm,
  } = useBarrierActions();

  const handleNext = () => {
    next();
  };

  const handlePrev = () => {
    prev();
  };

  const renderBox = (
    item: GetBarriersResponse,
    showTick: boolean
  ): JSX.Element => {
    let className = styles.wrapper;

    if (item.isTicked || item.isSelected) {
      className = `${className} ${styles.wrapperActive}`;
    }

    return (
      <div key={`item-${item.barrierId}`} className="relative">
        <Flex
          gap={10}
          align="flex-start"
          justify="space-between"
          className={className}
        >
          <div
            className={styles.iconWrapper}
            onClick={() =>
              setSelectedItem({
                ...item,
                timeStampEpoch: Date.now(),
                isSelected: !item.isSelected,
              })
            }
            aria-hidden
          >
            {showTick && (
              <div className={styles.icon}>
                {item.isTicked && <FaCheck size={25} color="var(--primary)" />}
              </div>
            )}
            <Typography.Text>{item.content}</Typography.Text>
          </div>
        </Flex>
      </div>
    );
  };

  return (
    <>
      <Row
        gutter={[16, 16]}
        justify="space-between"
        align="top"
        className="mb-16 w-full rounded-lg border border-gray p-5"
      >
        <Col xs={24} sm={12} md={8} lg={6}>
          {isLoading ? (
            <SkeletonCommon />
          ) : (
            <>
              <EEYellowTickBanner title={t("wfa.highlightBox.step_1")} />
              <div className="grid max-w-xs gap-y-5">
                <SCCheckboxButtonHeading
                  title={t("wfa.highlightBox_step3.step_1.sub_heading")}
                />
                {categoryBarriersAndActionData.map(
                  (item: GetBarriersResponse) => {
                    return renderBox(item, true);
                  }
                )}
              </div>
            </>
          )}
        </Col>

        <Col xs={24} sm={12} md={8} lg={6}>
          {isLoading ? (
            <SkeletonCommon />
          ) : (
            <>
              <EEYellowTickBanner title={t("wfa.highlightBox.step_2")} />
              <div className="grid max-w-xs gap-y-5">
                <SCCheckboxButtonHeading
                  title={t("wfa.highlightBox_step3.step_1.sub_heading4")}
                />
                {barrierData?.map((item: GetBarriersResponse) => {
                  return renderBox(item, true);
                })}
              </div>
            </>
          )}
        </Col>

        <Col xs={24} sm={12} md={8} lg={6}>
          {isLoading ? (
            <SkeletonCommon />
          ) : (
            <>
              <EEYellowTickBanner title={t("wfa.highlightBox.step_3")} />
              <div className="grid max-w-xs gap-y-5">
                <SCCheckboxButtonHeading
                  title={t("wfa.highlightBox_step3.step_1.sub_heading3")}
                />
                {measureData?.map((item: GetBarriersResponse) => {
                  return renderBox(item, false);
                })}
              </div>
            </>
          )}

          {showConfirmbtn && !isWABarrierSubmitted ? (
            <SCButton
              type="primary"
              className="primary-btn mt-[20px] w-full font-bold custom-sm:h-[40px]"
              onClick={() => setShowConfirmationForm(true)}
            >
              {t("wfa.actions.confirm")}
            </SCButton>
          ) : null}
        </Col>

        <Col xs={24} sm={12} md={8} lg={6}>
          {showConfirmationForm || isWABarrierSubmitted ? (
            <>
              <SCCheckboxButtonHeading
                title={t("wfa.highlightBox_step3.step_1.sub_heading4")}
              />
              <BarrierConfirmationForm
                categoryBarriersAndActionData={categoryBarriersAndActionData}
                handleNext={handleNext}
                submittedWABarriersData={submittedWABarriersData?.result}
                isWABarrierSubmitted={isWABarrierSubmitted}
              />
            </>
          ) : null}
        </Col>
      </Row>

      {isWABarrierSubmitted && (
        <Row justify="center" className="mb-12">
          <Col span={12}>
            <SCButton
              className="min-w-32 !bg-[#D4D5D6] font-bold text-primary sm:w-[160px]"
              scType={SC_BUTTON_TYPE.UPLOADBUTTON}
              onClick={handlePrev}
            >
              {t("wfa.actions.back")}
            </SCButton>
          </Col>
          <Col span={12} className="flex justify-end">
            <SCButton className="min-w-32" onClick={handleNext}>
              {t("wfa.actions.primary")}
            </SCButton>
          </Col>
        </Row>
      )}
    </>
  );
};

export default EEBarrierActions;
