import * as React from "react";

type ECModalTitleProps = {
  title?: React.ReactNode;
  className?: string;
};

const ECModalTitle: React.FC<ECModalTitleProps> = (
  props: ECModalTitleProps
) => {
  const { title = "", className = "" } = props;
  return <div className={className}>{title}</div>;
};

export default ECModalTitle;
