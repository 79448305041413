type IBtnHeading = {
  title?: string;
};

const SCCheckboxButtonHeading = ({ title = "" }: IBtnHeading): JSX.Element => {
  return (
    <div className="min-h-10 !w-full rounded-lg bg-primary px-4 py-2 text-[var(--btn-text)]">
      {title}
    </div>
  );
};
export default SCCheckboxButtonHeading;
