import { Form, FormItemProps } from "antd";
import { ButtonProps } from "antd/lib";
import * as React from "react";
import SCButton from "../../SCButton";

type SCSubmitButtonProps = {
  itemProps: FormItemProps;
  inputProps: ButtonProps;
  children?: React.ReactNode;
  scType?: string;
  loading?: boolean;
};

const SCSubmitButton: React.FC<SCSubmitButtonProps> = (
  props: SCSubmitButtonProps
) => {
  const { itemProps, inputProps, scType } = props;
  const { className, ...restInputProps } = inputProps;
  return (
    <Form.Item {...itemProps}>
      <SCButton
        className={className + " primary-btn"}
        scType={scType}
        htmlType="submit"
        {...restInputProps}
      >
        {props.children}
      </SCButton>
    </Form.Item>
  );
};

export default SCSubmitButton;
