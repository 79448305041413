import * as React from "react";
import ECModalTitle from "../ECModalTitle";
import { Modal, ModalProps } from "antd";

type ECModalProps = ModalProps & {
  title?: React.ReactNode;
  children?: React.ReactNode;
  titleClasses?: string;
};

const ECModal: React.FC<ECModalProps> = (props: ECModalProps) => {
  const { title, children, titleClasses, ...restprops } = props;
  return (
    <Modal
      title={<ECModalTitle title={title} className={titleClasses} />}
      centered={true}
      maskClosable={false}
      {...restprops}
    >
      {children}
    </Modal>
  );
};

export default ECModal;
