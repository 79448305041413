import { MutateOptions, useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { GenericResponse } from "../../Teams/service/teamsServices";
import {
  CreateEntityPayload,
  DeleteEntityPayload,
  GetEEPlanStatusPayload,
  GetEntityByKeyPayload,
  UpdateEntityPayload,
  createEntity,
  deleteEntity,
  getEEPlanStatus,
  getEmployeesToManageEntities,
  getEntities,
  getEntityByKey,
  updateEntity,
} from "../services/entityServices";

export const GET_EE_PLAN_STATUS_QUERY_KEY = "GET_EE_PLAN_STATUS_QUERY_KEY";
export const useGetEEPlanStatus = (params: GetEEPlanStatusPayload) => {
  const resp = useQuery({
    queryKey: [GET_EE_PLAN_STATUS_QUERY_KEY, params],
    queryFn: async () => {
      const response: any = await getEEPlanStatus(params);
      return response;
    },
    enabled: !!params.entityRecordKey,
  });

  return resp;
};

export const GET_ENTITY_BY_KEY_QUERY_KEY = "GET_ENTITY_BY_KEY_QUERY_KEY";
export const useGetEntityByKey = (params: GetEntityByKeyPayload) => {
  const resp = useQuery({
    queryKey: [GET_ENTITY_BY_KEY_QUERY_KEY, params],
    queryFn: async () => {
      const response = await getEntityByKey(params);
      return response;
    },
    enabled: !!params.entityRecordKey,
  });

  return resp;
};

export const GET_ENTITIES_QUERY_KEY = "GET_ENTITIES_QUERY_KEY";
export const useGetEntities = () => {
  const resp = useQuery({
    queryKey: [GET_ENTITIES_QUERY_KEY],
    queryFn: async () => {
      const response = await getEntities();
      return response;
    },
    refetchOnWindowFocus: false,
  });

  return resp;
};

export const useCreateEntity = (
  options?: MutateOptions<
    GenericResponse<void>,
    AxiosError,
    CreateEntityPayload
  >
) => {
  return useMutation({
    mutationFn: createEntity,
    ...options,
    onSuccess: (data, vars, context) => {
      if (options?.onSuccess) {
        options.onSuccess(data, vars, context);
      }
    },
    onError: (error, vars, context) => {
      if (options?.onError) {
        options.onError(error, vars, context);
      }
    },
  });
};

export const useUpdateEntity = (
  options?: MutateOptions<
    GenericResponse<void>,
    AxiosError,
    UpdateEntityPayload
  >
) => {
  return useMutation({
    mutationFn: updateEntity,
    ...options,
    onSuccess: (data, vars, context) => {
      if (options?.onSuccess) {
        options.onSuccess(data, vars, context);
      }
    },
    onError: (error, vars, context) => {
      if (options?.onError) {
        options.onError(error, vars, context);
      }
    },
  });
};

export const useDeleteEntity = (
  options?: MutateOptions<
    GenericResponse<void>,
    AxiosError,
    DeleteEntityPayload
  >
) => {
  return useMutation({
    mutationFn: deleteEntity,
    ...options,
    onSuccess: (data, vars, context) => {
      if (options?.onSuccess) {
        options.onSuccess(data, vars, context);
      }
    },
    onError: (error, vars, context) => {
      if (options?.onError) {
        options.onError(error, vars, context);
      }
    },
  });
};

export const GET_EMPLOYEES_TO_MANAGE_ENTITIES_QUERY_KEY =
  "GET_EMPLOYEES_TO_MANAGE_ENTITIES_QUERY_KEY";
export const useGetEmployeesToManageEntities = () => {
  const resp = useQuery({
    queryKey: [GET_EMPLOYEES_TO_MANAGE_ENTITIES_QUERY_KEY],
    queryFn: async () => {
      const response = await getEmployeesToManageEntities();
      return response;
    },
    refetchOnWindowFocus: false,
  });

  return resp;
};
