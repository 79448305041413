import { Typography } from "antd";

import { ReactComponent as TickMark } from "../../../../../assets/images/svg/tick-down.svg";

type IEEYellowTickBanner = {
  withTickIcon?: boolean;
  title: string;
};

const EEYellowTickBanner = ({
  withTickIcon = true,
  title,
}: IEEYellowTickBanner) => {
  return (
    <div
      className={`mb-5 flex min-h-20 min-w-10 flex-col items-center rounded-lg bg-textLightYellow px-4 py-5`}
    >
      <Typography.Title className="text-center" level={5}>
        {title}
      </Typography.Title>
      {withTickIcon ? <TickMark /> : null}
    </div>
  );
};

export default EEYellowTickBanner;
