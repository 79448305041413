import { EEWorkforceProfileTableType } from "../presentation/EmploymentEquityWFP/Tables/EEWorkforceTable";

export const EE_WFP_RECORDS_PER_PAGE = 50;

export const EE_WFP_TABLE_DEFAULT_CHECKED: Partial<
  keyof EEWorkforceProfileTableType
>[] = [
  "employeeFirstName",
  "employeeSurname",
  "emailAddress",
  "employeeNumber",
];

export const EEWorkForceProfileTableFilterFields: Partial<
  Record<keyof EEWorkforceProfileTableType, string>
> = {
  employeeFirstName: "Employee First Name",
  employeeSurname: "Employee Surname",
  employeeNumber: "Employee Number",
  emailAddress: "Email Address",
  contactNumber: "Contact Number",
  idNumber: "ID Number",
  passportNumber: "Passport number",
  foreignNational: "Foreign National",
  dateOfBirth: "Date of Birth",
  age: "Age",
  gender: "Gender",
  ethnicity: "Ethnicity",
  streetAddress: "Street Address",
  suburb: "Suburb",
  city: "City",
  provinceId: "Province/State",
  countryName: "Country",
  postalCode: "Postal Code",
  branch: "Branch",
  department: "Department",
  businessUnit: "Business Unit",
  jobTitle: "Job Title",
  employmentStatus: "Employment Status",
  startDate: "Start Date",
  endDate: "End Date",
  ofoCode: "OFO Code",
  occupationTitle: "Occupation Title",
  occupationalLevel: "Occupational Level",
  wasPromoted: "Was Promoted",
  lastPromotionDate: "Last Promotion Date",
  terminationDate: "Termination Date",
  terminationReason: "Termination Reason",
  isDisabled: "Disabled",
  disabilitySpecification: "Disability Specification",
  userType: "User Type",
};

export const QUARTER_OPTIONS = [
  {
    value: 1,
    label: "EE Committee Meeting Quarter One",
  },
  {
    value: 2,
    label: "EE Committee Meeting Quarter Two",
  },
  {
    value: 3,
    label: "EE Committee Meeting Quarter Three",
  },
  {
    value: 4,
    label: "EE Committee Meeting Quarter Four",
  },
];
