import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import ArrowSeparaterIcon from "../../../../../core/component/SVGComponents/ArrowSeparaterIcon";
import { ROUTES_CONST } from "../../../../../core/models/constants/core.constants";
import { useGetEEPlanStatus } from "../../../../Entity/hooks/entity.hook";
import useHasUserEditEntityPermissions from "../../../hooks/useHasUserEditEntityPermissions";
import { SkeletonCommon } from "../WorkforceAnalysis/containers/WorkforceAnalysisContainer/WorkforceAnalysisContainer";

const EEPlanOutlet: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const lastSlug = location.pathname.split("/").pop();
  const { hasUserEditEntityPermission, pendingStatus } =
    useHasUserEditEntityPermissions();

  const { data: eePlanStatus } = useGetEEPlanStatus({
    entityRecordKey: id ?? "",
  });

  const [disabledSectionIds, setDisabledSectionIds] = useState([1, 2, 3]);
  const [completedSectionIds, setCompletedSectionIds] = useState([0]);

  useEffect(() => {
    if (eePlanStatus) {
      const { result } = eePlanStatus;
      const { sectionId, sectionStatus } = result;

      if (sectionStatus) {
        const filteredArray = disabledSectionIds.filter(
          (item: number) => item > sectionId + 1
        );
        setDisabledSectionIds(filteredArray);
        if (sectionId === 1) {
          setCompletedSectionIds([1]);
        } else if (sectionId === 2) {
          setCompletedSectionIds([1, 2]);
        } else {
          setCompletedSectionIds([1, 2, 3]);
        }
      } else {
        const filteredArray = disabledSectionIds.filter(
          (item: number) => item > sectionId
        );
        setDisabledSectionIds(filteredArray);

        if (sectionId === 1) {
          setCompletedSectionIds([0]);
        } else if (sectionId === 2) {
          setCompletedSectionIds([1]);
        } else {
          setCompletedSectionIds([1, 2]);
        }
      }
    }
  }, [eePlanStatus]);

  const stepperHandler = (
    stepperNumber: number,
    label: string,
    slug: string,
    handler: () => void
  ) => {
    let checkSlug = lastSlug === slug;
    const isDisabled = disabledSectionIds.includes(stepperNumber);
    const isCompleted = completedSectionIds.includes(stepperNumber);

    return (
      <div
        className={`flex grow items-center justify-center text-center ${isDisabled ? "cursor-not-allowed bg-lightGray" : "cursor-pointer"}`}
        aria-hidden
        onClick={() => {
          !isDisabled && handler();
        }}
      >
        <span
          className={`flex h-[40px] w-[40px] items-center justify-center rounded-full border-2 font-medium ${checkSlug ? "border-primary" : "border-[#C1C1C1] text-[#2D2B29]"} ${isCompleted ? "border-primary bg-primary text-white" : "text-primary"}`}
        >
          {stepperNumber}
        </span>
        <span
          className={`${checkSlug ? "text-primary" : "text-[#2D2B29]"} ml-[10px] font-medium`}
        >
          {label}
        </span>
      </div>
    );
  };
  return (
    <>
      {pendingStatus ? (
        <SkeletonCommon />
      ) : (
        <>
          {hasUserEditEntityPermission ? (
            <>
              <div className="flex w-full flex-col items-start justify-center border-b border-[#C1C1C1] sm:flex-row sm:items-center">
                {stepperHandler(
                  1,
                  t("eePlanConfiguration.content.step1"),
                  ROUTES_CONST.EE_PLAN_CONFIGURATION,
                  () => {
                    navigate(
                      `${ROUTES_CONST.HOME}${ROUTES_CONST.EMPLOYMENT_EQUITY}/${id}/${ROUTES_CONST.EE_PLAN}/${ROUTES_CONST.EE_PLAN_CONFIGURATION}`
                    );
                  }
                )}

                <ArrowSeparaterIcon height={50} className="hidden sm:block" />

                {stepperHandler(
                  2,
                  t("eePlanConfiguration.content.step2"),
                  ROUTES_CONST.EE_WORKFORCE_ANALYSIS,
                  () => {
                    navigate(
                      `${ROUTES_CONST.HOME}${ROUTES_CONST.EMPLOYMENT_EQUITY}/${id}/${ROUTES_CONST.EE_PLAN}/${ROUTES_CONST.EE_WORKFORCE_ANALYSIS}`
                    );
                  }
                )}
                <ArrowSeparaterIcon height={50} className="hidden sm:block" />
                {stepperHandler(
                  3,
                  t("eePlanConfiguration.content.step3"),
                  ROUTES_CONST.PLAN_CONFIGURATION,
                  () => {
                    navigate(
                      `${ROUTES_CONST.HOME}${ROUTES_CONST.EMPLOYMENT_EQUITY}/${id}/${ROUTES_CONST.EE_PLAN}/${ROUTES_CONST.PLAN_CONFIGURATION}`
                    );
                  }
                )}
              </div>
              <Outlet />
            </>
          ) : (
            <div className="mt-[65px] flex flex-col items-center">
              <p className="text-gray-400 text-center text-[28px] font-medium not-italic leading-[42px]">
                {t("eePlanConfiguration.content.noAccess")}
              </p>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default EEPlanOutlet;
