import { useQueryClient } from "@tanstack/react-query";
import { Tabs } from "antd";
import { isEmpty, isString } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { PrevStateProps } from "../../../../../../../../hooks/useStepsNav";
import { setGlobleState } from "../../../../../../../../store/slice/GlobleSlice";
import { NOTIFICATION_CONST } from "../../../../../../../core/models/constants/core.constants";
import { GenericResponse } from "../../../../../../Teams/service/teamsServices";
import { SkeletonCommon } from "../../containers/WorkforceAnalysisContainer/WorkforceAnalysisContainer";
import { groupedByOccupation } from "../../helpers/helper";
import {
  GET_OCCUPATION_LEVEL_STRATEGY_ANALYSIS_KEY,
  useFetchStrategyNarrative,
  useFetchStrategyNarrativeActionExamples,
  useGetOccupationLevelStrategyAnalysis,
  usePostWorkforceAnalysisStrategySelection,
} from "../../hooks/WorkforceAnalysis.hooks";
import { OCCUPATION_LEVELS } from "../../services/type.service";
import { postWorkforceAnalysisStrategySelectionPayloadBody } from "../../services/wfa.services";
import TabContent from "./TabContent";

const EEOccupationalLevel = ({ prev }: Readonly<PrevStateProps>) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const tabRef = useRef<HTMLDivElement>(null);

  const [activeKey, setActiveKey] = useState(OCCUPATION_LEVELS.TOP_MANAGEMENT);

  const { data, isLoading } = useGetOccupationLevelStrategyAnalysis({
    entityRecordKey: id ?? "",
  });

  const { data: narretiveData } = useFetchStrategyNarrative({
    entityRecordKey: id ?? "",
  });

  const { data: strategyNarrativeActionData } =
    useFetchStrategyNarrativeActionExamples({
      entityRecordKey: id ?? "",
    });

  const postWorkforceAnalysisStrategySelection =
    usePostWorkforceAnalysisStrategySelection();

  const [TMdata, setTMData] =
    useState<postWorkforceAnalysisStrategySelectionPayloadBody>();
  const [SMdata, setSMData] =
    useState<postWorkforceAnalysisStrategySelectionPayloadBody>();
  const [MMdata, setMMData] =
    useState<postWorkforceAnalysisStrategySelectionPayloadBody>();
  const [JMdata, setJMData] =
    useState<postWorkforceAnalysisStrategySelectionPayloadBody>();
  const [Sdata, setSData] =
    useState<postWorkforceAnalysisStrategySelectionPayloadBody>();
  const [USdata, setUSData] =
    useState<postWorkforceAnalysisStrategySelectionPayloadBody>();

  const workForceAnalysisStrategiesSelection = useMemo(() => {
    return data?.result && groupedByOccupation(data?.result);
  }, [data?.result]);

  const onChange = (key: string) => {
    setActiveKey(key as OCCUPATION_LEVELS);
  };

  useEffect(() => {
    if (
      !isEmpty(TMdata) &&
      !isEmpty(SMdata) &&
      !isEmpty(MMdata) &&
      !isEmpty(JMdata) &&
      !isEmpty(TMdata) &&
      !isEmpty(Sdata) &&
      !isEmpty(USdata)
    ) {
      submitWorkforceAnalysis();
    }
  }, [TMdata, SMdata, MMdata, JMdata, Sdata, USdata]);

  const scrollToTab = () => {
    if (tabRef.current) {
      const top = tabRef.current.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({ top: top - 130, behavior: "smooth" });
    }
  };

  const ocuupationLevelStrategyStateHandler = (
    occupationKey: OCCUPATION_LEVELS,
    obj: postWorkforceAnalysisStrategySelectionPayloadBody
  ) => {
    switch (occupationKey) {
      case OCCUPATION_LEVELS.TOP_MANAGEMENT:
        setTMData(obj);
        setActiveKey(OCCUPATION_LEVELS.SENIOR_MANAGEMENT);
        scrollToTab();
        break;
      case OCCUPATION_LEVELS.SENIOR_MANAGEMENT:
        setSMData(obj);
        setActiveKey(OCCUPATION_LEVELS.MIDDLE_MANAGEMENT);
        scrollToTab();
        break;
      case OCCUPATION_LEVELS.MIDDLE_MANAGEMENT:
        setMMData(obj);
        setActiveKey(OCCUPATION_LEVELS.JUNIOR_MANAGEMENT);
        scrollToTab();
        break;
      case OCCUPATION_LEVELS.JUNIOR_MANAGEMENT:
        setJMData(obj);
        setActiveKey(OCCUPATION_LEVELS.SEMI_SKILLED);
        scrollToTab();
        break;
      case OCCUPATION_LEVELS.SEMI_SKILLED:
        setSData(obj);
        setActiveKey(OCCUPATION_LEVELS.UNSKILLED);
        scrollToTab();
        break;
      default:
        setUSData(obj);
    }
  };

  const submitWorkforceAnalysis = () => {
    const postWorkforceAnalysisStrategySelectionPayloadBody: postWorkforceAnalysisStrategySelectionPayloadBody[] =
      [];
    if (!isEmpty(TMdata))
      postWorkforceAnalysisStrategySelectionPayloadBody.push(TMdata);
    if (!isEmpty(SMdata))
      postWorkforceAnalysisStrategySelectionPayloadBody.push(SMdata);
    if (!isEmpty(MMdata))
      postWorkforceAnalysisStrategySelectionPayloadBody.push(MMdata);
    if (!isEmpty(JMdata))
      postWorkforceAnalysisStrategySelectionPayloadBody.push(JMdata);
    if (!isEmpty(Sdata))
      postWorkforceAnalysisStrategySelectionPayloadBody.push(Sdata);
    if (!isEmpty(USdata))
      postWorkforceAnalysisStrategySelectionPayloadBody.push(USdata);

    const requestBody = {
      postWorkforceAnalysisStrategySelectionPayloadBody,
      entityId: id ?? "",
    };

    postWorkforceAnalysisStrategySelection.mutate(requestBody, {
      onSuccess(data) {
        dispatch(
          setGlobleState({
            error: {
              message: t("wfa.OLA.successInfo"),
              type: NOTIFICATION_CONST.SUCCESS,
            },
          })
        );
        queryClient.invalidateQueries({
          queryKey: [GET_OCCUPATION_LEVEL_STRATEGY_ANALYSIS_KEY],
        });
      },
      onError(error) {
        const errorData = error.response?.data as GenericResponse<void>;
        if (isString(errorData.errorMessage)) {
          const errorMessage = errorData.errorMessage
            ? errorData.errorMessage
            : t("faerrorFallback.message");

          dispatch(
            setGlobleState({
              error: {
                message: errorMessage,
                type: NOTIFICATION_CONST.ERROR,
              },
            })
          );
        }
      },
    });
  };

  const handlePrev = () => {
    prev();
  };

  return (
    <>
      <div className="m-8 !w-full text-center">
        <span className="text-center text-[32px] font-bold text-primary">
          {t("wfa.OLA.title")}
        </span>
      </div>
      <div ref={tabRef}>
        <Tabs
          activeKey={activeKey}
          defaultActiveKey={OCCUPATION_LEVELS.TOP_MANAGEMENT}
          onChange={onChange}
          type="card"
          centered
          items={[
            OCCUPATION_LEVELS.TOP_MANAGEMENT,
            OCCUPATION_LEVELS.SENIOR_MANAGEMENT,
            OCCUPATION_LEVELS.MIDDLE_MANAGEMENT,
            OCCUPATION_LEVELS.JUNIOR_MANAGEMENT,
            OCCUPATION_LEVELS.SEMI_SKILLED,
            OCCUPATION_LEVELS.UNSKILLED,
          ]?.map((key) => {
            return {
              label: key,
              key: key,
              children: (
                <>
                  {workForceAnalysisStrategiesSelection &&
                  narretiveData?.result &&
                  strategyNarrativeActionData?.result ? (
                    <TabContent
                      masterData={
                        workForceAnalysisStrategiesSelection[key][0]
                          .entityEAPMasterDtos
                      }
                      allData={workForceAnalysisStrategiesSelection[key][0]}
                      analysisData={
                        workForceAnalysisStrategiesSelection[key][0]
                          .workForceAnalysisStrategiesSelectionDtos
                      }
                      occupationKey={key}
                      isLoading={isLoading}
                      ocuupationLevelStrategyStateHandler={
                        ocuupationLevelStrategyStateHandler
                      }
                      narretiveData={narretiveData?.result}
                      strategyNarrativeActionData={
                        strategyNarrativeActionData?.result
                      }
                      handlePrev={handlePrev}
                      postWorkforceAnalysisStrategySelectionLoader={
                        postWorkforceAnalysisStrategySelection.isPending
                      }
                    />
                  ) : (
                    <SkeletonCommon />
                  )}
                </>
              ),
            };
          })}
        />
      </div>
    </>
  );
};

export default EEOccupationalLevel;
