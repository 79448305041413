import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { GetBarriersResponse } from "../services/wfa.services";
import {
  useFetchBarriers,
  useGetSubmittedWABarriersData,
} from "./WorkforceAnalysis.hooks";

const updatedBarriersDataFilterHandler = (
  updatedBarriersData: GetBarriersResponse[],
  ids: string
) => {
  let categoryIds = ids ?? "";
  return updatedBarriersData?.filter((data: GetBarriersResponse) => {
    if (
      data.barrierCategoryParentId === 0 &&
      data.barrierNarrativeParentId === 0
    ) {
      data.isCategory = true;
      data.barrierList = [];
      data.isTicked = categoryIds.includes(data.barrierId.toString());
      data.content = data.barrierCategory;

      return data;
    }
  });
};

const filterBarrierHandler = (
  updatedBarriersData: GetBarriersResponse[],
  ids: string
) => {
  let barrierNaritiveIds = ids ?? "";
  return updatedBarriersData?.filter((data: GetBarriersResponse) => {
    if (
      data.barrierCategoryParentId > 0 &&
      data.barrierNarrativeParentId === 0
    ) {
      data.isBarrier = true;
      data.measureList = [];
      data.isTicked = barrierNaritiveIds.includes(data.barrierId.toString());
      data.content = data.barrierNarrative;

      return data;
    }
  });
};

const filterMeasureHandler = (
  updatedBarriersData: GetBarriersResponse[],
  ids: string
) => {
  let affarmativeActionMeasureIds = ids ?? "";
  return updatedBarriersData?.filter((data: GetBarriersResponse) => {
    if (data.barrierCategoryParentId > 0 && data.barrierNarrativeParentId > 0) {
      data.isMeasure = true;
      data.isTicked = affarmativeActionMeasureIds.includes(
        data.barrierId.toString()
      );
      data.content = data.affirmativeActionMeasure;
      return data;
    }
  });
};

const useBarrierActions = () => {
  const { id } = useParams();
  const { data: initialBarriersData, isLoading } = useFetchBarriers();

  const { data: submittedWABarriersData } = useGetSubmittedWABarriersData({
    entityRecordKey: id ?? "",
  });
  const [isWABarrierSubmitted, setIsWABarrierSubmitted] =
    useState<boolean>(false);

  useEffect(() => {
    if (
      submittedWABarriersData?.result?.entityWorkForceAnalysisKey !==
      "00000000-0000-0000-0000-000000000000"
    ) {
      setIsWABarrierSubmitted(true);
    } else {
      setIsWABarrierSubmitted(false);
    }
  }, [submittedWABarriersData]);

  const [showConfirmationForm, setShowConfirmationForm] =
    useState<boolean>(false);

  const [showConfirmbtn, setShowConfirmbtn] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<GetBarriersResponse>();
  const [barrierData, setBarrierData] = useState<
    GetBarriersResponse[] | never[]
  >([]);
  const [measureData, setMeasureData] = useState<GetBarriersResponse[]>([]);

  const transformCategoryData = () => {
    let copy = [...categoryBarriersAndActionData];
    copy.forEach((category: GetBarriersResponse) => {
      if (category.barrierId === selectedItem?.barrierId) {
        category.isSelected = selectedItem.isSelected;
        selectedItem.isSelected
          ? setBarrierData(category.barrierList)
          : setBarrierData([]);
      } else {
        if (category.isSelected) {
          category.isSelected = false;
        }
        category.barrierList.forEach((barrier: GetBarriersResponse) => {
          barrier.isSelected = false;
        });
      }
    });
    setMeasureData([]);
    setCategoryBarriersAndActionData(copy);
  };

  // Handles selection of barriers within a category
  const handleBarrierSelection = (
    category: GetBarriersResponse,
    selectedItem: any
  ) => {
    category.barrierList.forEach((barrier: GetBarriersResponse) => {
      if (barrier.barrierId === selectedItem.barrierId) {
        barrier.isSelected = selectedItem.isSelected;
        selectedItem.isSelected
          ? setMeasureData(barrier.measureList)
          : setMeasureData([]);
      } else {
        barrier.isSelected = false;
      }
    });
    setBarrierData(category.barrierList);
  };

  // Resets selection for categories and barriers
  const resetCategorySelection = (category: GetBarriersResponse) => {
    if (category.isSelected) {
      category.isSelected = false;
    }
    category.barrierList.forEach((barrier: GetBarriersResponse) => {
      barrier.isSelected = false;
    });
  };

  // Main function to transform the barrier data
  const transformBarrierData = () => {
    let copy = [...categoryBarriersAndActionData];
    copy.forEach((category: GetBarriersResponse) => {
      if (category.barrierId === selectedItem?.barrierCategoryParentId) {
        category.isSelected = selectedItem.isSelected;
        handleBarrierSelection(category, selectedItem);
      } else {
        resetCategorySelection(category);
      }
    });

    setCategoryBarriersAndActionData(copy);
  };

  // Handles measure selection within a barrier
  const handleMeasureSelection = (
    barrier: GetBarriersResponse,
    selectedItem: any
  ) => {
    barrier.measureList.forEach((measure: GetBarriersResponse) => {
      if (measure.barrierId === selectedItem.barrierId) {
        measure.isSelected = selectedItem.isSelected;
        measure.isTicked = selectedItem.isSelected;
        setMeasureData(barrier.measureList);
      }
    });

    const tickedMeasures = barrier.measureList.filter(
      (measure: GetBarriersResponse) => measure.isTicked
    );
    barrier.isSelected = selectedItem.isSelected;
    barrier.isTicked = tickedMeasures.length > 0;
  };

  // Handles barrier selection within a category
  const handleBarrierSelectionTransformMeasureData = (
    category: GetBarriersResponse,
    selectedItem: any
  ) => {
    category.barrierList.forEach((barrier: GetBarriersResponse) => {
      if (barrier.barrierId === selectedItem.barrierNarrativeParentId) {
        handleMeasureSelection(barrier, selectedItem);
        setBarrierData(category.barrierList);
      }
    });

    const tickedBarriers = category.barrierList.filter(
      (barrier: GetBarriersResponse) => barrier.isTicked
    );
    category.isSelected = selectedItem.isSelected;
    category.isTicked = tickedBarriers.length > 0;
  };

  // Main function to transform measure data
  const transformMeasureData = () => {
    let copy = [...categoryBarriersAndActionData];

    copy.forEach((category: GetBarriersResponse) => {
      if (category.barrierId === selectedItem?.barrierCategoryParentId) {
        handleBarrierSelectionTransformMeasureData(category, selectedItem);
      } else {
        resetSelection(category);
      }
    });

    setCategoryBarriersAndActionData(copy);
  };

  // Resets selection for unselected categories and barriers
  const resetSelection = (category: GetBarriersResponse) => {
    category.isSelected = false;
    category.barrierList.forEach((barrier: GetBarriersResponse) => {
      barrier.isSelected = false;
    });
  };

  useEffect(() => {
    if (selectedItem) {
      if (selectedItem.isCategory) {
        transformCategoryData();
      } else if (selectedItem.isBarrier) {
        transformBarrierData();
      } else {
        transformMeasureData();
      }
    }
  }, [selectedItem]);

  const { barriersTransformedData } = useMemo(() => {
    if (!initialBarriersData) {
      return { barriersTransformedData: [] };
    }

    const updatedBarriersData = initialBarriersData.map(
      (item: GetBarriersResponse) => ({
        ...item,
        isCategory: false,
        isBarrier: false,
        isMeasure: false,

        timeStampEpoch: Date.now(), // used it to render the component easily by updating this prop
        isTicked: false, // it becomes true when measure actions are selected
        isSelected: false, // it is just for showing current selection
        content: "",
      })
    );

    let categoryList = updatedBarriersDataFilterHandler(
      updatedBarriersData,
      submittedWABarriersData?.result?.categoryIds || ""
    );

    let barrierList = filterBarrierHandler(
      updatedBarriersData,
      submittedWABarriersData?.result?.barrierNaritiveIds ?? ""
    );

    let measureList = filterMeasureHandler(
      updatedBarriersData,
      submittedWABarriersData?.result?.affarmativeActionMeasureIds ?? ""
    );

    measureList?.forEach((measure: GetBarriersResponse) => {
      const barrierData = barrierList.find((barrier: GetBarriersResponse) => {
        return barrier.barrierId === measure.barrierNarrativeParentId;
      });
      if (barrierData?.measureList) {
        barrierData?.measureList.push(measure);
      }
    });

    barrierList?.forEach((barrier: GetBarriersResponse) => {
      const categoryData = categoryList.find(
        (category: GetBarriersResponse) => {
          return category.barrierId === barrier.barrierCategoryParentId;
        }
      );
      if (categoryData?.barrierList) {
        categoryData?.barrierList.push(barrier);
      }
    });

    const barriersTransformedData = categoryList;
    return { barriersTransformedData };
  }, [initialBarriersData, submittedWABarriersData]);

  const [categoryBarriersAndActionData, setCategoryBarriersAndActionData] =
    useState<GetBarriersResponse[]>(barriersTransformedData);

  const setInitialCategoryBarriersAndActionsData = () => {
    setCategoryBarriersAndActionData(barriersTransformedData);
    let firstIsTickedCategory = barriersTransformedData.find(
      (category: GetBarriersResponse) => category.isTicked
    );

    if (firstIsTickedCategory) {
      let firstIsTickedBarrier = firstIsTickedCategory.barrierList.find(
        (barrier: GetBarriersResponse) => barrier.isTicked
      );
      if (firstIsTickedBarrier) {
        setBarrierData(firstIsTickedCategory.barrierList);
        setMeasureData(firstIsTickedBarrier.measureList);
      }
    }
  };

  useEffect(() => {
    let firstIsTickedCategory = categoryBarriersAndActionData.find(
      (category: GetBarriersResponse) => category.isTicked
    );

    if (firstIsTickedCategory) {
      setShowConfirmbtn(true);
    } else {
      setShowConfirmbtn(false);
    }
  }, [categoryBarriersAndActionData]);

  useEffect(() => {
    barriersTransformedData.length > 0 &&
      setInitialCategoryBarriersAndActionsData();
  }, [barriersTransformedData]);

  return {
    id,
    barriersTransformedData,
    isLoading,
    submittedWABarriersData,
    isWABarrierSubmitted,
    showConfirmbtn,

    setSelectedItem,
    categoryBarriersAndActionData,
    barrierData,
    measureData,

    showConfirmationForm,
    setShowConfirmationForm,
  };
};

export default useBarrierActions;
