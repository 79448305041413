import { FC } from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as CheckCircle } from "../../../../../../../../assets/images/svg/chec-box.svg";
import ECModal from "../../../../../../../core/component/ui/ECModal";
import SCButton from "../../../../../../../core/component/ui/SCButton";
import { SC_BUTTON_TYPE } from "../../../../../../../core/models/constants/core.constants";

type ConfirmSaveModalProps = {
  isModalOpen: boolean;
  modalCloseHandler: Function;
  submitHandler: Function;
  closable?: boolean;
};

const BarrierAndActionConfirmationModal: FC<ConfirmSaveModalProps> = (
  props: ConfirmSaveModalProps
) => {
  const { isModalOpen, modalCloseHandler, submitHandler, closable } = props;

  const { t } = useTranslation();

  return (
    <ECModal
      title={""}
      width={500}
      centered
      open={isModalOpen}
      titleClasses={"text-primary 2xl:text-2xl text-lg font-bold font-primary"}
      closable={closable}
      onCancel={() => modalCloseHandler()}
      classNames={{
        header: "text-primary text-[32px] font-bold font-secondary",
        body: "modal-content",
        content:
          "!rounded-[35px] border border-gray bg-white shadow-commonBox !py-[36px] !px-9 text-primary z-[999]",
      }}
      footer={null}
      wrapClassName="block custom-modal-wrap"
    >
      <div className="mb-5 flex flex-col items-center justify-center 2xl:mb-10">
        <CheckCircle
          style={{ height: "117px", width: "117px" }}
          className="m-1 h-14 w-14 font-bold text-green"
        />
        <div className="flex justify-center text-[32px] font-bold">
          {t("myCourse.modal.heading.confirmDelete")}
        </div>
        <div className="mb-10 mt-5 flex justify-center text-center">
          {t("wfa.highlightBox_step3.step_2.confirmation_info")}
        </div>
        <div className="flex justify-between gap-8">
          <SCButton
            onClick={() => {
              modalCloseHandler();
            }}
            className="w-full !border-0 !bg-btnSecondaryBg sm:w-[160px] md:w-[200px]"
            // className="gray-btn h-[45px] w-full caption-bottom rounded-[50px] !border-0 !border-[#D4D5D6] !bg-[#D4D5D6] text-base font-bold text-primary hover:!bg-[#D4D5D6] sm:w-[160px] md:w-[200px]"
            scType={SC_BUTTON_TYPE.SECONDARY}
          >
            {t("Skip")}
          </SCButton>

          <SCButton
            onClick={() => {
              submitHandler();
            }}
            className="w-full md:w-[200px]"
            scType={SC_BUTTON_TYPE.PRIMARY}
          >
            <span>{t("saveNContinue")}</span>
          </SCButton>
        </div>
      </div>
    </ECModal>
  );
};

export default BarrierAndActionConfirmationModal;
