import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { GetOccupationLevelStrategyAnalysisRes } from "../services/type.service";
import {
  strategyNarrativeListResponse,
  strategyNarretiveActionsResponse,
} from "../services/wfa.services";

type useStrategiesSelectionProps = {
  narretiveData: strategyNarrativeListResponse[];
  strategyNarrativeActionData: strategyNarretiveActionsResponse[];
  allData: GetOccupationLevelStrategyAnalysisRes;
};

export type actionListDataType = {
  isNarretive: boolean;
  isAction: boolean;
  timeStampEpoch: number;
  isTicked: boolean;
  isSelected: boolean;
  content: string;

  strategyActionExampleId: number;
  strategyActionExampleText: string;
  strategyId: number;
};

export type NarretiveAndActionDataType = {
  isNarretive: boolean;
  isAction: boolean;
  timeStampEpoch: number;
  isTicked: boolean;
  isSelected: boolean;
  content: string;

  strategyId: number;
  strategyNarrative: string;

  actionList: actionListDataType[];
  strategyActionExampleId: number;
};

const useStrategiesSelection = (props: useStrategiesSelectionProps) => {
  const { narretiveData, strategyNarrativeActionData, allData } = props;

  const { id } = useParams();

  const [isWAStrategiesSubmitted, setIsWAStrategiesSubmitted] =
    useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (
      allData?.entityWorkForceAnalysisStrategiesSelectionKey !==
      "00000000-0000-0000-0000-000000000000"
    ) {
      setIsWAStrategiesSubmitted(true);
      setStrategiesApplicable(true);
    } else {
      setIsWAStrategiesSubmitted(false);
      setStrategiesApplicable(false);
    }
  }, [allData]);

  const [showConfirmbtn, setShowConfirmbtn] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<
    actionListDataType | NarretiveAndActionDataType
  >();
  const [actionsData, setActionsData] = useState<
    actionListDataType[] | never[]
  >([]);
  const [isStrategiesApplicable, setStrategiesApplicable] =
    useState<boolean>(false);

  const transformNarretiveData = () => {
    let copy = [...narretiveAndActionData];
    copy.forEach((narretive: NarretiveAndActionDataType) => {
      if (narretive.strategyId === selectedItem?.strategyId) {
        narretive.isSelected = selectedItem.isSelected;
        selectedItem.isSelected
          ? setActionsData(narretive.actionList)
          : setActionsData([]);
      } else {
        if (narretive.isSelected) {
          narretive.isSelected = false;
        }
        narretive.actionList.forEach((action: actionListDataType) => {
          action.isSelected = false;
        });
      }
    });
    setNarretiveAndActionData(copy);
  };

  const transformActionsData = () => {
    let copy = [...narretiveAndActionData];
    copy.forEach((narretive: NarretiveAndActionDataType) => {
      if (narretive.strategyId === selectedItem?.strategyId) {
        narretive.actionList.forEach((action: actionListDataType) => {
          if (
            action.strategyActionExampleId ===
            selectedItem?.strategyActionExampleId
          ) {
            action.isSelected = selectedItem.isSelected;
            action.isTicked = selectedItem.isSelected;
            setActionsData(narretive.actionList);
          }
        });

        let tickedActions = narretive.actionList.filter(
          (action: actionListDataType) => action.isTicked
        );
        narretive.isSelected = selectedItem.isSelected;
        narretive.isTicked = tickedActions.length > 0;
      } else {
        if (narretive.isSelected) {
          narretive.isSelected = false;
        }
        narretive.actionList.forEach((action: actionListDataType) => {
          action.isSelected = false;
        });
      }
    });
    setNarretiveAndActionData(copy);
  };

  useEffect(() => {
    if (selectedItem) {
      if (selectedItem.isNarretive) {
        transformNarretiveData();
      } else {
        transformActionsData();
      }
    }
  }, [selectedItem]);

  const { strategiesTransformedData } = useMemo(() => {
    if (!narretiveData || !strategyNarrativeActionData) {
      return { strategiesTransformedData: [] };
    }

    let narretiveIds = allData?.strategyNarratives ?? "";

    let actionsIds = allData?.strategyNarrativesAction ?? "";

    const updatedStrategyData = narretiveData.map(
      (item: strategyNarrativeListResponse) => {
        let actions = strategyNarrativeActionData?.filter(
          (action: strategyNarretiveActionsResponse) => {
            return action.strategyId === item.strategyId;
          }
        );

        const updatedAction = actions.map(
          (item: strategyNarretiveActionsResponse) => {
            let obj = {
              ...item,
              isNarretive: false,
              isAction: true,

              timeStampEpoch: Date.now(),
              isTicked: actionsIds.includes(
                item.strategyActionExampleId.toString()
              ),
              isSelected: false,
              content: item.strategyActionExampleText,
            };

            return obj;
          }
        );

        let obj = {
          ...item,
          isNarretive: true,
          isAction: false,

          timeStampEpoch: Date.now(),
          isTicked: narretiveIds.includes(item.strategyId.toString()),
          isSelected: false,
          content: item.strategyNarrative,
          actionList: updatedAction,
          strategyActionExampleId: -1, // as there will no strategyActionExampleId in narretives
        };
        return obj;
      }
    );

    const strategiesTransformedData: NarretiveAndActionDataType[] =
      updatedStrategyData;
    setIsLoading(false);
    return { strategiesTransformedData };
  }, [narretiveData, strategyNarrativeActionData, allData]);

  const [narretiveAndActionData, setNarretiveAndActionData] = useState<
    NarretiveAndActionDataType[]
  >(strategiesTransformedData);

  const setInitialStrategyAndActionsData = () => {
    setNarretiveAndActionData(strategiesTransformedData);
    let firstIsTickedNarretive = strategiesTransformedData.find(
      (nareetive: NarretiveAndActionDataType) => nareetive.isTicked
    );

    if (firstIsTickedNarretive) {
      setActionsData(firstIsTickedNarretive.actionList);
    }
  };

  useEffect(() => {
    let firstIsTickedNarretive = narretiveAndActionData.find(
      (narretive: NarretiveAndActionDataType) => narretive.isTicked
    );

    if (firstIsTickedNarretive) {
      setShowConfirmbtn(true);
    } else {
      setShowConfirmbtn(false);
    }
  }, [narretiveAndActionData]);

  useEffect(() => {
    strategiesTransformedData.length > 0 && setInitialStrategyAndActionsData();
  }, [strategiesTransformedData]);

  const getSelectedElementsIdArray = () => {
    const narretiveIdsArray: number[] = [];
    const barrierNaritiveIdsArray: number[] = [];

    narretiveAndActionData?.forEach((narretive: NarretiveAndActionDataType) => {
      if (narretive.isTicked) {
        narretiveIdsArray.push(narretive.strategyId);

        narretive.actionList?.forEach((action: actionListDataType) => {
          if (action.isTicked) {
            barrierNaritiveIdsArray.push(action.strategyActionExampleId);
          }
        });
      }
    });

    return { narretiveIdsArray, barrierNaritiveIdsArray };
  };

  return {
    id,
    strategiesTransformedData,
    isLoading,
    isWAStrategiesSubmitted,
    showConfirmbtn,

    setSelectedItem,
    narretiveAndActionData,
    actionsData,
    isStrategiesApplicable,
    setStrategiesApplicable,
    getSelectedElementsIdArray,
  };
};

export default useStrategiesSelection;
