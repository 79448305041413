import { Row, Skeleton, StepProps } from "antd";
import { type FC } from "react";

import useStepsNav from "../../../../../../../../hooks/useStepsNav";
import i18n from "../../../../../../../../i18next";
import SCSteps from "../../../../../../../core/component/ui/SCSteps";
import { isMobile } from "../../../../../../../core/helper/DeviceHelper";
import BarrierActionMobileView from "../../presentation/BarrierActionMobileView";
import EEAnalysisReport from "../../presentation/EEAnalysisReport/EEAnalysisReport";
import EEBarrierActions from "../../presentation/EEBarrierActions/EEBarrierActions";
import EEOccupationalLevel from "../../presentation/EEOccupationalLevel";

interface StepItem {
  title: string;
  content: React.ComponentType<{
    next: () => void;
    prev: () => void;
  }>;
}

export const SkeletonCommon = () => (
  <>
    <Skeleton active className="mb-5" />
    <Skeleton active className="mb-5" />
  </>
);

const itemList: StepItem[] = [
  {
    title: i18n.t("wfa.steps.1"),
    content: EEAnalysisReport,
  },
  {
    title: i18n.t("wfa.steps.2"),
    content: isMobile() ? BarrierActionMobileView : EEBarrierActions,
  },
  {
    title: i18n.t("wfa.steps.3"),
    content: EEOccupationalLevel,
  },
];

const items: StepProps[] = itemList?.map(({ title }) => ({
  key: title,
  title,
  className: "md:max-w-[130px] lg:max-w-full",
}));

const WorkforceAnalysisContainer: FC = () => {
  const { current, next, prev } = useStepsNav();

  const CurrentComponent = itemList[current]?.content;

  return (
    <div>
      <Row
        justify="center"
        className="mx-auto mt-10 grid w-full place-content-center"
      >
        <SCSteps
          current={current}
          items={items}
          labelPlacement="vertical"
          size="small"
          className="mx-2 justify-center md:min-w-[730px]"
        />
      </Row>

      <Row
        justify="center"
        className="mx-2 mt-3 flex flex-col justify-center sm:mx-3 md:mx-5 lg:mx-10"
      >
        <div className="w-full lg:grid lg:w-11/12 lg:self-center">
          {!!CurrentComponent && (
            <CurrentComponent key={current || 0} next={next} prev={prev} />
          )}
        </div>
      </Row>
    </div>
  );
};

export default WorkforceAnalysisContainer;
