import { useTranslation } from "react-i18next";

import { isMobile } from "../../../../../../../../core/helper/DeviceHelper";
import { neededKey, processWorkForceData } from "../../../helpers/helper";
import useTransformOccupationLevelStrategyAnalysis from "../../../hooks/useTransformOccupationLevelStrategyAnalysis";
import {
  GetOccupationLevelStrategyAnalysisRes,
  IAnalysisStrategies,
  IEntityEAPMasterDtos,
  OCCUPATION_LEVELS,
} from "../../../services/type.service";
import {
  strategyNarrativeListResponse,
  strategyNarretiveActionsResponse,
} from "../../../services/wfa.services";
import AnalysisFindingsTable from "./AnalysisTable/AnalysisFindingsTable";
import LevelAnalysisTable from "./AnalysisTable/LevelAnalysisTable";
import EEStrategiesSelection from "./EEStrategiesSelection";
import EEStrategiesSelectionMobileView from "./EEStrategiesSelectionMobileView";

type TabContentProps = {
  masterData?: IEntityEAPMasterDtos;
  occupationKey: OCCUPATION_LEVELS;
  analysisData: IAnalysisStrategies[];
  allData: GetOccupationLevelStrategyAnalysisRes;
  isLoading: boolean;
  ocuupationLevelStrategyStateHandler: Function;
  narretiveData: strategyNarrativeListResponse[];
  strategyNarrativeActionData: strategyNarretiveActionsResponse[];
  handlePrev: () => void;
  postWorkforceAnalysisStrategySelectionLoader: boolean;
};

const TabContent = (props: TabContentProps) => {
  const {
    masterData,
    occupationKey,
    analysisData,
    isLoading,
    allData,
    ocuupationLevelStrategyStateHandler,
    narretiveData,
    strategyNarrativeActionData,
    handlePrev,
    postWorkforceAnalysisStrategySelectionLoader,
  } = props;
  const { t } = useTranslation();

  const { actualDetailedData, findingDetailedData } =
    useTransformOccupationLevelStrategyAnalysis({
      analysisData,
      masterData,
    });

  const saveStrategySelectionLocallyHandler = (
    narretiveIdsArray: number[],
    barrierNaritiveIdsArray: number[]
  ) => {
    let obj = {
      workforceAnalysisStrategiesCreateDtos: processWorkForceData(
        [
          ...actualDetailedData.map((data) => ({ ...data })),
          ...findingDetailedData.map((data) => ({ ...data })),
        ].filter(({ name }) => name && neededKey.includes(name))
      ),
      entityWorkForceAnalysisStrategiesSelectionKey:
        allData.entityWorkForceAnalysisStrategiesSelectionKey,
      snapshotDate: allData.snapshotDate,
      eapMasterId: masterData?.entityEAPMasterId,
      occupationId: allData.occupationId,
      isStrategyApplied: narretiveIdsArray.length > 0,
      strategyNarratives: narretiveIdsArray?.join(",") || "",
      strategyNarrativesAction: barrierNaritiveIdsArray?.join(",") || "",
    };

    ocuupationLevelStrategyStateHandler(occupationKey, obj);
  };

  return (
    <div>
      <LevelAnalysisTable
        actualDetailedData={actualDetailedData}
        isLoading={isLoading}
      />
      <div>
        <div className="m-8 !ml-0 mb-4 !w-full">
          <span className="text-[20px] font-semibold text-primary">
            {t("wfa.OLA.finding")}
          </span>
        </div>
        <AnalysisFindingsTable
          occupationKey={occupationKey}
          findingDetailedData={findingDetailedData}
          isLoading={isLoading}
        />
      </div>

      {isMobile() ? (
        <EEStrategiesSelectionMobileView
          allData={allData}
          saveStrategySelectionLocallyHandler={
            saveStrategySelectionLocallyHandler
          }
          narretiveData={narretiveData}
          strategyNarrativeActionData={strategyNarrativeActionData}
          handlePrev={handlePrev}
          postWorkforceAnalysisStrategySelectionLoader={
            postWorkforceAnalysisStrategySelectionLoader
          }
        />
      ) : (
        <EEStrategiesSelection
          allData={allData}
          saveStrategySelectionLocallyHandler={
            saveStrategySelectionLocallyHandler
          }
          narretiveData={narretiveData}
          strategyNarrativeActionData={strategyNarrativeActionData}
          handlePrev={handlePrev}
          postWorkforceAnalysisStrategySelectionLoader={
            postWorkforceAnalysisStrategySelectionLoader
          }
        />
      )}
    </div>
  );
};

export default TabContent;
