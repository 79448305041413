import { Form, FormItemProps, Select } from "antd";
import { SelectProps } from "antd/lib";
import { FC } from "react";

type SCSelectProps = {
  itemProps: FormItemProps;
  selectProps: SelectProps;
};

const SCSelect: FC<SCSelectProps> = (props: SCSelectProps) => {
  const { className, colon, ...restItemProps } = props.itemProps;

  return (
    <Form.Item
      className={"ant-form-item-label-show " + className}
      colon={false}
      {...restItemProps}
    >
      <Select {...props.selectProps} />
    </Form.Item>
  );
};

export default SCSelect;
