import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { EE_WFP_TABLE_DEFAULT_CHECKED } from "../../modules/features/EmploymentEquity/helpers/constant";
import { EEWorkforceProfileTableType } from "../../modules/features/EmploymentEquity/presentation/EmploymentEquityWFP/Tables/EEWorkforceTable";

interface EEWorkforceProfileTableSlice {
  visibleColumns: Partial<keyof EEWorkforceProfileTableType>[];
}

const initialState: EEWorkforceProfileTableSlice = {
  visibleColumns: EE_WFP_TABLE_DEFAULT_CHECKED,
};

export const EEWorkForceProfileTableSlice = createSlice({
  name: "EEWorkForceProfileTable",
  initialState,
  reducers: {
    setEEWorkforceProfileTableState: (
      state,
      action: PayloadAction<EEWorkforceProfileTableSlice>
    ) => {
      state.visibleColumns = action.payload.visibleColumns;
    },
  },
});

export const { setEEWorkforceProfileTableState } =
  EEWorkForceProfileTableSlice.actions;

export default EEWorkForceProfileTableSlice.reducer;
