import { Table, TableProps } from "antd";
import { ColumnsType } from "antd/lib/table";
import { TableComponents } from "rc-table/lib/interface";

// Define a type constraint to ensure T is an object type
type AnyObject = { [key: string]: any };

// TODO: Rather than creating own type extends those types from antd table RefTable interface
type SCTableProps<T extends AnyObject> = {
  components?: TableComponents<T>;
  columns: ColumnsType<T>;
  dataSource: T[];
  rowClassName?: (record: T, index: number) => string;
  pagination?: TableProps<T>["pagination"];
  className?: string;
  loading?: boolean;
  scroll?: TableProps<T>["scroll"];
  bordered?: TableProps<T>["bordered"];
  onChange?: TableProps<T>["onChange"];
  rowSelection?: TableProps<T>["rowSelection"];
  rowKey?: string;
  defaultExpandAllRows?: boolean;
};

// Default row class name function
export const rowClassName = (_: any, index: number) => {
  return index % 2 === 0 ? "even-row" : "";
};

const SCTable = <T extends AnyObject>({
  columns,
  dataSource,
  rowClassName,
  pagination,
  className,
  loading,
  scroll,
  ...restTableProps
}: SCTableProps<T>) => {
  return (
    <Table<T>
      columns={columns}
      dataSource={dataSource}
      rowClassName={rowClassName}
      pagination={pagination ? { ...pagination } : false}
      className={className}
      loading={loading}
      scroll={scroll}
      {...restTableProps}
    />
  );
};

export default SCTable;
