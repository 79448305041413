import { useState } from "react";

export type NextStateProps = {
  next: () => void;
};

export type PrevStateProps = {
  prev: () => void;
};

const useStepsNav = ({ maxSteps = 3 } = {}) => {
  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent((prevValue) => {
      const newValue = prevValue + 1;
      if (newValue > maxSteps) return prevValue;
      return newValue;
    });
  };

  const prev = () => {
    setCurrent((prevValue) => {
      const newValue = prevValue - 1;
      if (newValue < 0) return 0;
      return newValue;
    });
  };

  return { current, next, prev };
};

export default useStepsNav;
