import { useQueryClient } from "@tanstack/react-query";
import { Checkbox, Form, Skeleton } from "antd";
import dayjs, { Dayjs } from "dayjs";
import isString from "lodash/isString";
import { FieldData } from "rc-field-form/es/interface";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { setGlobleState } from "../../../../../../../../store/slice/GlobleSlice";
import SCFormDatePicker from "../../../../../../../core/component/ui/form/SCFormDatePicker";
import SCSelect from "../../../../../../../core/component/ui/form/SCSelect";
import SCSubmitButton from "../../../../../../../core/component/ui/form/SCSubmitButton";
import {
  DATE_TIME_CONSTANTS,
  NOTIFICATION_CONST,
  SC_BUTTON_TYPE,
} from "../../../../../../../core/models/constants/core.constants";
import { GenericResponse } from "../../../../../../WorkforceProfile/service/workforceServices";
import {
  FETCH_WORKFORCE_ANALYSIS_KEY,
  useGetJobTitleListService,
  usePostWorkforceAnalysis,
} from "../../hooks/WorkforceAnalysis.hooks";
import {
  GetBarriersResponse,
  GetWorkforceAnalysisResponse,
  PostWorkforceAnalysisRequestBodyPayload,
} from "../../services/wfa.services";
import BarrierAndActionConfirmationModal from "../BarrierAndActionConfirmationModal";

type ISelectedList = {
  categoryBarriersAndActionData?: GetBarriersResponse[];
  handleNext: () => void;
  submittedWABarriersData: GetWorkforceAnalysisResponse | undefined;
  isWABarrierSubmitted: boolean;
};

const barrierChekboxOptions = [
  { label: "Policy", value: "Policy" },
  { label: "Practice", value: "Practice" },
  { label: "Procedure", value: "Procedure" },
];

const BarrierConfirmationForm = ({
  categoryBarriersAndActionData,
  handleNext,
  submittedWABarriersData,
  isWABarrierSubmitted,
}: ISelectedList) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { id: entityId } = useParams();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const postWorkforceAnalysis = usePostWorkforceAnalysis();

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [initialbarrierChekboxValues, setInitialbarrierChekboxValues] =
    useState<string[]>([""]);
  const [startDate, setStartDate] = useState<dayjs.Dayjs>(dayjs());
  const [endDate, setEndDate] = useState<dayjs.Dayjs>(dayjs());

  const { data: jobTitleList, isLoading: jobTitleListLoading } =
    useGetJobTitleListService();

  const jobTitles = useMemo(() => {
    return jobTitleList?.map(({ jobTitleID, jobTitleName }) => {
      return {
        label: jobTitleName,
        value: jobTitleID,
      };
    });
  }, [jobTitleList]);

  const setupInitialData = () => {
    let defaultValue = [];

    if (submittedWABarriersData?.policy) {
      defaultValue.push("Policy");
    }
    if (submittedWABarriersData?.practice) {
      defaultValue.push("Practice");
    }
    if (submittedWABarriersData?.procedures) {
      defaultValue.push("Procedure");
    }
    setInitialbarrierChekboxValues(defaultValue);
    form.setFieldValue("confirmBarriers", defaultValue);

    if (submittedWABarriersData?.jobTitleId) {
      form.setFieldValue("jobTitleId", submittedWABarriersData?.jobTitleId);
    }

    setStartDate(
      dayjs(
        submittedWABarriersData?.barrierStartDate ||
          submittedWABarriersData?.eePlanStartDate,
        DATE_TIME_CONSTANTS.DATE_FORMAT_SERVER
      )
    );

    setEndDate(
      dayjs(
        submittedWABarriersData?.barrierEndDate ||
          submittedWABarriersData?.eePlanEndDate,
        DATE_TIME_CONSTANTS.DATE_FORMAT_SERVER
      )
    );
  };
  useEffect(() => {
    setupInitialData();
  }, [submittedWABarriersData]);

  const handleBarrierChange = (checkedValues: string[]) => {
    setInitialbarrierChekboxValues(checkedValues);
  };

  const handleSubmit = async () => {
    await form.validateFields();
    const barrierStartDate = dayjs(
      form.getFieldValue("barrierStartDate")
    ).format(DATE_TIME_CONSTANTS.DATE_FORMAT_SERVER);

    const barrierEndDate = dayjs(form.getFieldValue("barrierEndDate")).format(
      DATE_TIME_CONSTANTS.DATE_FORMAT_SERVER
    );

    const jobTitleId = form.getFieldValue("jobTitleId");
    const policy = initialbarrierChekboxValues.includes("Policy");
    const practice = initialbarrierChekboxValues.includes("Practice");
    const procedure = initialbarrierChekboxValues.includes("Procedure");

    const categoryIdsArray: number[] = [];
    const barrierNaritiveIdsArray: number[] = [];
    const affarmativeActionMeasureIdsArray: number[] = [];

    categoryBarriersAndActionData?.forEach((category: GetBarriersResponse) => {
      if (category.isTicked) {
        categoryIdsArray.push(category.barrierId);

        category.barrierList?.forEach((barrier: GetBarriersResponse) => {
          if (barrier.isTicked) {
            barrierNaritiveIdsArray.push(barrier.barrierId);

            barrier.measureList?.forEach((measure: GetBarriersResponse) => {
              if (measure.isTicked) {
                affarmativeActionMeasureIdsArray.push(measure.barrierId);
              }
            });
          }
        });
      }
    });

    const fields = {
      categoryIds: categoryIdsArray?.join(",") || "",
      barrierNaritiveIds: barrierNaritiveIdsArray?.join(",") || "",
      affarmativeActionMeasureIds:
        affarmativeActionMeasureIdsArray?.join(",") || "",

      policy: policy,
      practice: practice,
      procedures: procedure,

      barrierStartDate,
      barrierEndDate,
      jobTitleId,
    };

    submitWorkforceAnalysis(fields);

    setShowConfirmationModal(false);
  };

  const submitWorkforceAnalysis = (
    fields: PostWorkforceAnalysisRequestBodyPayload
  ) => {
    const body = {
      requestBody: fields,
      entityId: entityId || "",
    };
    postWorkforceAnalysis.mutate(body, {
      onSuccess(data) {
        dispatch(
          setGlobleState({
            error: {
              message: data.message as string,
              type: NOTIFICATION_CONST.SUCCESS,
            },
          })
        );
        queryClient.invalidateQueries({
          queryKey: [FETCH_WORKFORCE_ANALYSIS_KEY],
        });
        handleNext();
      },
      onError(error) {
        const errorData = error.response?.data as GenericResponse<void>;
        if (isString(errorData.errorMessage)) {
          const errorMessage = errorData.errorMessage
            ? errorData.errorMessage
            : t("errorFallback.message");

          dispatch(
            setGlobleState({
              error: {
                message: errorMessage,
                type: NOTIFICATION_CONST.ERROR,
              },
            })
          );
        } else {
          form.setFields([...(errorData.errorMessage as FieldData[])]);
        }
      },
    });
  };

  const onChangeStartDate = (value: Dayjs | null) => {
    const selectedStartDate = value || new Dayjs();
    setStartDate(selectedStartDate);
  };

  const onChangeEndDate = (value: Dayjs | null) => {
    const selectedEndDate = value || new Dayjs();
    setEndDate(selectedEndDate);
  };

  const disabledStartDate = (date: dayjs.Dayjs) => {
    let eePlanStartDate = dayjs(
      submittedWABarriersData?.eePlanStartDate,
      DATE_TIME_CONSTANTS.DATE_FORMAT_SERVER
    );

    let eePlanEndDate = dayjs(
      submittedWABarriersData?.eePlanEndDate,
      DATE_TIME_CONSTANTS.DATE_FORMAT_SERVER
    );

    return date && (date < eePlanStartDate || date > eePlanEndDate);
  };

  const disabledEndDate = (date: dayjs.Dayjs) => {
    let eePlanEndDate = dayjs(
      submittedWABarriersData?.eePlanEndDate,
      DATE_TIME_CONSTANTS.DATE_FORMAT_SERVER
    );

    return date && (date > eePlanEndDate || date < startDate);
  };

  return (
    <div>
      <Form
        form={form}
        className="w-full gap-y-10"
        name="register"
        onFinish={() => setShowConfirmationModal(true)}
        scrollToFirstError
      >
        <div className="mb-[20px] mt-[20px]">
          <Form.Item
            prefixCls="customFormItem"
            name="confirmBarriers"
            valuePropName="value"
            rules={[
              {
                required: true,
                message: t(
                  "wfa.section_2.input.confirmBarriers.validationErrors.blank"
                ),
              },
            ]}
          >
            <Checkbox.Group
              options={barrierChekboxOptions}
              prefixCls="customFormItem"
              value={initialbarrierChekboxValues}
              onChange={(checkedValue: string[]) =>
                handleBarrierChange(checkedValue)
              }
              className="flex flex-col gap-y-[10px]"
            ></Checkbox.Group>
          </Form.Item>
        </div>
        <div className="mb-[20px]">
          <SCFormDatePicker
            value={startDate}
            form={form}
            label={t("wfa.section_2.input.startDate.label")}
            name="barrierStartDate"
            onChange={onChangeStartDate}
            rules={[
              {
                required: true,
                message: t(
                  "wfa.section_2.input.startDate.validationErrors.blank"
                ),
              },
            ]}
            disabledDate={disabledStartDate}
          />
        </div>

        <div className="mb-[20px]">
          <SCFormDatePicker
            value={endDate}
            form={form}
            label={t("wfa.section_2.input.endDate.label")}
            name="barrierEndDate"
            onChange={onChangeEndDate}
            rules={[
              {
                required: true,
                message: t(
                  "wfa.section_2.input.endDate.validationErrors.blank"
                ),
              },
            ]}
            disabledDate={disabledEndDate}
          />
        </div>

        <div className="mb-[20px]">
          {jobTitleListLoading ? (
            <Skeleton.Input active block size="large" />
          ) : null}
          {!jobTitleListLoading ? (
            <SCSelect
              itemProps={{
                prefixCls: "customFormItem",
                className: "!mb-5",
                name: t("wfa.section_2.input.jobTitle.name"),
                rules: [
                  {
                    required: true,
                    message: t(
                      "wfa.section_2.input.jobTitle.validationErrors.blank"
                    ),
                  },
                ],
              }}
              selectProps={{
                showSearch: true,
                allowClear: true,
                optionFilterProp: "label",
                options: jobTitles,
                placeholder: t("wfa.section_2.input.jobTitle.placeHolder"),
              }}
            />
          ) : null}
        </div>
        {!isWABarrierSubmitted && (
          <SCSubmitButton
            itemProps={{}}
            inputProps={{
              className: "w-full h-[45px]",
              loading: postWorkforceAnalysis.isPending,
            }}
            scType={SC_BUTTON_TYPE.SUBMIT_PRIMARY}
          >
            {t("wfa.actions.save")}
          </SCSubmitButton>
        )}
      </Form>

      {showConfirmationModal && (
        <BarrierAndActionConfirmationModal
          isModalOpen={showConfirmationModal}
          modalCloseHandler={() => {
            setShowConfirmationModal(false);
          }}
          submitHandler={() => {
            handleSubmit();
          }}
        />
      )}
    </div>
  );
};

export default BarrierConfirmationForm;
