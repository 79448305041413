import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { RootState } from "../../store";
import { ROUTES_CONST } from "../core/models/constants/core.constants";
import {
  CORPORATE_USER_TYPE,
  TAP_USER_ROLES,
} from "../core/models/enums/tap.enum";
import { UserDetailInitialState } from "../core/models/types/userDetail.types";
import { useValidateManageTeamLink } from "../features/Teams/hooks/teams.hook";
import { MORE_MENU_OPTIONS } from "../features/layouts/presentation/ECHeader/menu.helper";

type Props = {
  allowedToIndividualUser: boolean;
  allowedRoles: any;
  pageURL?: string;
  children: JSX.Element;
};

const ProtectRoute = (props: Props) => {
  const { token } = useSelector((state: RootState) => state.auth);
  const { allowedToIndividualUser, allowedRoles, pageURL, children } = props;
  const { data: isEligibleForManageTeam } = useValidateManageTeamLink({
    token: token ?? "",
  });
  const userDetails: Partial<UserDetailInitialState> = useSelector(
    (state: RootState) => state.userDetail
  );
  const { role, isAdmin, isHR, isConsultantEmployee, isEEManager } =
    userDetails;

  const location = useLocation();
  const navigate = useNavigate();

  const checkPageLevelAuth = () => {
    switch (pageURL) {
      case MORE_MENU_OPTIONS.MANAGE_TEAMS:
        return isEligibleForManageTeam;
      case ROUTES_CONST.EMPLOYMENT_EQUITY:
      case MORE_MENU_OPTIONS.EMPLOYMENT_EQUITY_PORTAL:
        if (isConsultantEmployee || isEEManager) {
          return true;
        }
        return false;
      default:
        return false;
    }
  };

  const isAuthorized = () => {
    if (role === TAP_USER_ROLES.INDIVIDUAL_USER) {
      if (allowedToIndividualUser) {
        return true;
      } else {
        return false;
      }
    } else if (role === TAP_USER_ROLES.CORPORATE_USER) {
      if (isAdmin) {
        return allowedRoles.includes(CORPORATE_USER_TYPE.ADMIN);
      } else if (isHR) {
        return allowedRoles.includes(CORPORATE_USER_TYPE.HR);
      } else {
        if (pageURL) {
          return checkPageLevelAuth();
        } else {
          return allowedRoles.includes(CORPORATE_USER_TYPE.EMPLOYEE);
        }
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (!isAuthorized()) {
      navigate("/");
    }
  }, [userDetails, location]);

  return children;
};

export default ProtectRoute;
