import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Checkbox, Col, Row, theme } from "antd";
import { useTranslation } from "react-i18next";
import { FaCheck } from "react-icons/fa";

import SCButton from "../../../../../../../../../core/component/ui/SCButton";
import SCCollapse from "../../../../../../../../../core/component/ui/SCCollapse";
import { panelStyle } from "../../../../../../../../../core/helper/accordianPanleStyle";
import { SkeletonCommon } from "../../../../containers/WorkforceAnalysisContainer/WorkforceAnalysisContainer";
import useStrategiesSelection, {
  NarretiveAndActionDataType,
  actionListDataType,
} from "../../../../hooks/useStrategiesSelection";
import SCCheckboxButtonHeading from "../../../SCCheckboxButton/SCCheckboxButtonHeading";
import { EEStrategiesSelectionProps } from "../EEStrategiesSelection/EEStrategiesSelection";

const EEStrategiesSelectionMobileView = (props: EEStrategiesSelectionProps) => {
  const { token } = theme.useToken();

  const {
    allData,
    saveStrategySelectionLocallyHandler,
    narretiveData,
    strategyNarrativeActionData,
    handlePrev,
    postWorkforceAnalysisStrategySelectionLoader,
  } = props;
  const { t } = useTranslation();

  const {
    isLoading,
    isWAStrategiesSubmitted,
    showConfirmbtn,

    setSelectedItem,
    narretiveAndActionData,
    actionsData,
    isStrategiesApplicable,
    setStrategiesApplicable,
    getSelectedElementsIdArray,
  } = useStrategiesSelection({
    narretiveData,
    strategyNarrativeActionData,
    allData,
  });

  const saveStrategySelectionLocally = () => {
    const { narretiveIdsArray, barrierNaritiveIdsArray } =
      getSelectedElementsIdArray();

    saveStrategySelectionLocallyHandler(
      narretiveIdsArray,
      barrierNaritiveIdsArray
    );
  };

  const onChange = (
    key: string | string[],
    items: NarretiveAndActionDataType[]
  ) => {
    let id: string | string[] = key[0];
    if (id) {
      id = id.split("-");
      id = id[0];
      const selectedNarretive = items.find(
        (item: NarretiveAndActionDataType) => item.strategyId === Number(id)
      );
      if (selectedNarretive) {
        setSelectedItem({
          ...selectedNarretive,
          timeStampEpoch: Date.now(),
          isSelected: !selectedNarretive.isSelected,
        });
      }
    }
  };

  const renderBox = (item: NarretiveAndActionDataType) => {
    return (
      <div
        className={`relative py-[20px] pl-[30px] font-secondary text-sm font-semibold text-lightBlack md:text-lg 2xl:text-xl ${item.isTicked || item.isSelected ? "bg-bgLightBlue" : ""}`}
      >
        {item.isTicked && (
          <FaCheck
            className="absolute left-[10px] top-[23px]"
            size={15}
            color="var(--primary)"
          />
        )}
        {item?.content}
      </div>
    );
  };

  return (
    <>
      <div className="m-8 !ml-0 !w-full">
        <span className="text-[20px] font-semibold text-primary">
          {t("wfa.OLA.strategiesSelection")}
        </span>
        <div className="my-5">
          <Checkbox
            checked={isStrategiesApplicable}
            onChange={(e) => setStrategiesApplicable(e.target.checked)}
          >
            <span className="font-[20px] text-primary">
              {t("wfa.OLA.strategiesApplicable")}
            </span>
          </Checkbox>
        </div>
        {isStrategiesApplicable ? (
          <Row
            gutter={[16, 16]}
            justify="space-between"
            align="top"
            className="mb-16 w-full rounded-lg border border-gray p-5"
          >
            <Col xs={24} sm={12} md={12} lg={12}>
              {isLoading ? (
                <SkeletonCommon />
              ) : (
                <div className="help-support-accordion">
                  <div className="mb-[20px]">
                    <SCCheckboxButtonHeading title={"Categories"} />
                  </div>

                  <SCCollapse
                    collapseProps={{
                      onChange: (key: string | string[]) =>
                        onChange(key, narretiveAndActionData),
                      accordion: true,
                      bordered: false,
                      expandIconPosition: "right",
                      collapsible: "icon",
                      defaultActiveKey: ["1"],
                      expandIcon: ({ isActive }) =>
                        isActive ? (
                          <MinusOutlined className="!text-md !text-[#A9A7B1] 2xl:!text-[22px]" />
                        ) : (
                          <PlusOutlined className="!text-md !text-[#A9A7B1] 2xl:!text-[22px]" />
                        ),
                      style: { background: token.colorBgContainer },
                    }}
                    panelStyle={panelStyle}
                    collapseItems={narretiveAndActionData?.map(
                      (narretive: NarretiveAndActionDataType) => {
                        return {
                          key: `${narretive.strategyId}-strategyId`,
                          label: renderBox(narretive),
                          children: (
                            <div className="mx-[20px]">
                              <div className="mb-[20px] mt-[20px]">
                                <SCCheckboxButtonHeading
                                  title={t(
                                    "wfa.highlightBox_step3.step_1.sub_heading3"
                                  )}
                                />
                              </div>
                              {actionsData?.map(
                                (action: actionListDataType) => {
                                  return (
                                    <div
                                      className={`relative py-[20px] pl-[30px] font-secondary text-sm font-semibold text-lightBlack md:text-lg 2xl:text-xl ${action.isTicked || action.isSelected ? "bg-bgLightBlue" : ""}`}
                                      key={`${action.strategyActionExampleId}-strategyActionExampleId`}
                                      onClick={() =>
                                        setSelectedItem({
                                          ...action,
                                          timeStampEpoch: Date.now(),
                                          isSelected: !action.isSelected,
                                        })
                                      }
                                      aria-hidden
                                    >
                                      {action.content}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          ),
                        };
                      }
                    )}
                  />
                </div>
              )}

              {showConfirmbtn && !isWAStrategiesSubmitted ? (
                <SCButton
                  type="primary"
                  className="primary-btn mt-[20px] w-full font-bold custom-sm:h-[40px]"
                  onClick={() => saveStrategySelectionLocally()}
                  loading={postWorkforceAnalysisStrategySelectionLoader}
                >
                  {t("wfa.actions.confirm")}
                </SCButton>
              ) : null}
            </Col>
          </Row>
        ) : null}
      </div>

      <Row justify="center" className="mb-12">
        <Col span={24} className="flex justify-start">
          <SCButton className="min-w-32" onClick={handlePrev}>
            {t("wfa.actions.back")}
          </SCButton>
        </Col>
      </Row>
    </>
  );
};

export default EEStrategiesSelectionMobileView;
