import { CalendarOutlined } from "@ant-design/icons";
import { Calendar, Form, FormInstance, Input, Popover } from "antd";
import { Rule } from "antd/es/form";
import { InputProps } from "antd/lib";
import { SelectInfo } from "antd/lib/calendar/generateCalendar";
import dayjs, { Dayjs } from "dayjs";
import dayLocaleData from "dayjs/plugin/localeData";
import { useEffect, useState } from "react";

import { DATE_TIME_CONSTANTS } from "../../../../models/constants/core.constants";
import "../../form/SCInputText/SCInputText.css";
import DatePickerHeader from "./DatePickerHeader";

dayjs.extend(dayLocaleData);

type SCFormDatePickerProps = {
  form: FormInstance<any>;
  value?: dayjs.Dayjs;
  label: string;
  name: string;
  onChange: (value: Dayjs, dateString: string) => void;
  disabledDate?: (date: dayjs.Dayjs) => boolean;
  disabled?: boolean;
  rules?: Rule[];
  inputProps?: InputProps;
};

const wrapperStyle: React.CSSProperties = {
  marginTop: 10,
  width: 300,
  border: `1px solid #ccc`,
  borderRadius: 10,
};

const SCFormDatePicker = ({
  form,
  value,
  label,
  name,
  onChange,
  disabledDate,
  disabled = false,
  rules,
  inputProps,
}: SCFormDatePickerProps) => {
  const [selectedDate, setSelectedDate] = useState(value || dayjs());
  const handleOnSelect = (date: dayjs.Dayjs, selectInfo: SelectInfo) => {
    setSelectedDate(date);
    if (selectInfo.source === "date") {
      form.setFieldValue(name, date.format(DATE_TIME_CONSTANTS.DATE_FORMAT));
      onChange(date, date.format(DATE_TIME_CONSTANTS.DATE_FORMAT));
    }
  };

  useEffect(() => {
    if (value) {
      form.setFieldValue(name, value.format(DATE_TIME_CONSTANTS.DATE_FORMAT));
      setSelectedDate(value);
    }
    // eslint-disable-next-line
  }, [value]);

  const content = () => {
    return (
      <div style={wrapperStyle}>
        <Calendar
          fullscreen={false}
          headerRender={({ onChange }) => (
            <DatePickerHeader value={selectedDate} onChange={onChange} />
          )}
          onSelect={(date, selectInfo) => handleOnSelect(date, selectInfo)}
          value={selectedDate}
          disabledDate={disabledDate ? (date) => disabledDate(date) : undefined}
          className="sc-form-datepicker"
        />
      </div>
    );
  };

  return (
    <>
      {disabled ? (
        <Form.Item
          className={"ant-form-item-label-show"}
          label={label}
          name={name}
          rules={rules}
        >
          <Input
            suffix={<CalendarOutlined className="text-lg text-primary" />}
            className="border-1 border-gray py-0 text-base text-darkGray focus-within:border-gray hover:border-gray"
            readOnly
            disabled={disabled}
            {...inputProps}
          />
        </Form.Item>
      ) : (
        <Popover content={content} trigger={["hover", "click"]} fresh={true}>
          <Form.Item
            className={"ant-form-item-label-show"}
            label={label}
            name={name}
            rules={rules}
          >
            <Input
              suffix={<CalendarOutlined className="text-lg text-primary" />}
              className="border-1 border-gray py-0 text-base text-darkGray focus-within:border-gray hover:border-gray"
              readOnly
              disabled={disabled}
              {...inputProps}
            />
          </Form.Item>
        </Popover>
      )}
    </>
  );
};

export default SCFormDatePicker;
