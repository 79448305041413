import { API_URLS } from "../../core/models/enums/url.enums";
import axiosHttp from "../../core/services/Interceptors";

const { REACT_APP_API_URL } = process.env;

export const getJobTitleListService = () => {
  return axiosHttp?.get(
    REACT_APP_API_URL +
      API_URLS.BASE_WORK_EXEPERIENCE +
      API_URLS.GET_JOB_TITLE_LIST
  );
};

export const getIndustryListService = () => {
  return axiosHttp?.get(
    REACT_APP_API_URL + API_URLS.BASE_CLIENT + API_URLS.GET_INDUSTRY_LIST
  );
};

export const getCountryListService = () => {
  return axiosHttp?.get(REACT_APP_API_URL + API_URLS.API + API_URLS.COUNTRY);
};
