import { Steps, StepsProps } from "antd";

import styles from "./SCSteps.module.css";

interface IProps extends StepsProps {
  children?: React.ReactNode;
}

const SCSteps = ({ children, ...props }: IProps) => {
  const { className = "", ...restProps } = props;

  return (
    <Steps
      prefixCls="customSteps"
      className={`${styles.mySteps} ${className}`}
      {...restProps}
    >
      {children}
    </Steps>
  );
};

export default SCSteps;
