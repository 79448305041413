import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Col, Row, theme } from "antd";
import { useTranslation } from "react-i18next";

import SCButton from "../../../../../../../core/component/ui/SCButton";
import SCCollapse from "../../../../../../../core/component/ui/SCCollapse";
import { panelStyle } from "../../../../../../../core/helper/accordianPanleStyle";
import { SC_BUTTON_TYPE } from "../../../../../../../core/models/constants/core.constants";
import { SkeletonCommon } from "../../containers/WorkforceAnalysisContainer/WorkforceAnalysisContainer";
import useBarrierActions from "../../hooks/useBarrierActions";
import { GetBarriersResponse } from "../../services/wfa.services";
import BarrierConfirmationForm from "../BarrierConfirmationForm/BarrierConfirmationForm";
import SCCheckboxButtonHeading from "../SCCheckboxButton/SCCheckboxButtonHeading";
import SelectionBoxMobile from "../SelectionBoxMobile";

const BarrierActionMobileView = ({ next, prev }: any) => {
  const { t } = useTranslation();
  const { token } = theme.useToken();
  const {
    isLoading,
    submittedWABarriersData,
    isWABarrierSubmitted,
    showConfirmbtn,

    setSelectedItem,
    categoryBarriersAndActionData,
    barrierData,
    measureData,

    showConfirmationForm,
    setShowConfirmationForm,
  } = useBarrierActions();

  const handleNext = () => {
    next();
  };

  const onChange = (key: string | string[], items: GetBarriersResponse[]) => {
    let id: string | string[] = key[0];
    const selectedCategory = items.find(
      (item: GetBarriersResponse) => item.barrierId === Number(id)
    );
    if (selectedCategory) {
      setSelectedItem({
        ...selectedCategory,
        timeStampEpoch: Date.now(),
        isSelected: !selectedCategory.isSelected,
      });
    }
  };

  const handlePrev = () => {
    prev();
  };

  return (
    <>
      <Row
        gutter={[16, 16]}
        justify="space-between"
        align="top"
        className="mb-16 w-full rounded-lg border border-gray p-5"
      >
        <Col xs={24} sm={12} md={8} lg={6}>
          {isLoading ? (
            <SkeletonCommon />
          ) : (
            <div className="help-support-accordion">
              <div className="mb-[20px]">
                <SCCheckboxButtonHeading
                  title={t("wfa.highlightBox.step_1.sub_heading")}
                />
              </div>

              <SCCollapse
                collapseProps={{
                  onChange: (key: string | string[]) =>
                    onChange(key, categoryBarriersAndActionData),
                  accordion: true,
                  bordered: false,
                  expandIconPosition: "right",
                  collapsible: "icon",
                  expandIcon: ({ isActive }) =>
                    isActive ? (
                      <MinusOutlined className="!text-md !text-[#A9A7B1] 2xl:!text-[22px]" />
                    ) : (
                      <PlusOutlined className="!text-md !text-[#A9A7B1] 2xl:!text-[22px]" />
                    ),
                  style: { background: token.colorBgContainer },
                }}
                panelStyle={panelStyle}
                collapseItems={categoryBarriersAndActionData?.map(
                  (category: GetBarriersResponse) => {
                    return {
                      key: category?.barrierId,
                      label: <SelectionBoxMobile item={category} />,
                      children: (
                        <div className="mx-[20px]">
                          <div className="mb-[20px] mt-[20px]">
                            <SCCheckboxButtonHeading
                              title={t(
                                "wfa.highlightBox_step3.step_1.sub_heading2"
                              )}
                            />
                          </div>
                          <SCCollapse
                            collapseProps={{
                              onChange: (key: string | string[]) =>
                                onChange(key, barrierData),
                              accordion: true,
                              bordered: false,
                              expandIconPosition: "right",
                              collapsible: "icon",
                              defaultActiveKey: ["1"],
                              expandIcon: ({ isActive }) =>
                                isActive ? (
                                  <MinusOutlined className="!text-md !text-[#A9A7B1] 2xl:!text-[22px]" />
                                ) : (
                                  <PlusOutlined className="!text-md !text-[#A9A7B1] 2xl:!text-[22px]" />
                                ),
                              style: { background: token.colorBgContainer },
                            }}
                            panelStyle={panelStyle}
                            collapseItems={barrierData?.map(
                              (barrier: GetBarriersResponse) => {
                                return {
                                  key: barrier?.barrierId,
                                  label: <SelectionBoxMobile item={barrier} />,
                                  children: (
                                    <div className="mx-[20px]">
                                      <div className="mb-[20px] mt-[20px]">
                                        <SCCheckboxButtonHeading
                                          title={t(
                                            "wfa.highlightBox_step3.step_1.sub_heading2"
                                          )}
                                        />
                                      </div>
                                      {measureData?.map(
                                        (measure: GetBarriersResponse) => {
                                          return (
                                            <div
                                              className={`relative py-[20px] pl-[30px] font-secondary text-sm font-semibold text-lightBlack md:text-lg 2xl:text-xl ${measure.isTicked || measure.isSelected ? "bg-bgLightBlue" : ""}`}
                                              key={measure?.barrierId}
                                              onClick={() =>
                                                setSelectedItem({
                                                  ...measure,
                                                  timeStampEpoch: Date.now(),
                                                  isSelected:
                                                    !measure.isSelected,
                                                })
                                              }
                                              aria-hidden
                                            >
                                              {measure?.content}
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  ),
                                };
                              }
                            )}
                          />
                        </div>
                      ),
                    };
                  }
                )}
              />
            </div>
          )}
          {showConfirmbtn && !isWABarrierSubmitted ? (
            <SCButton
              type="primary"
              className="primary-btn mt-[20px] w-full font-bold custom-sm:h-[40px]"
              onClick={() => setShowConfirmationForm(true)}
            >
              {t("wfa.actions.confirm")}
            </SCButton>
          ) : null}
        </Col>

        <Col xs={24} sm={12} md={8} lg={6}>
          {showConfirmationForm || isWABarrierSubmitted ? (
            <>
              <SCCheckboxButtonHeading
                title={t("wfa.highlightBox_step3.step_1.sub_heading4")}
              />
              <BarrierConfirmationForm
                categoryBarriersAndActionData={categoryBarriersAndActionData}
                handleNext={handleNext}
                submittedWABarriersData={submittedWABarriersData?.result}
                isWABarrierSubmitted={isWABarrierSubmitted}
              />
            </>
          ) : null}
        </Col>
      </Row>
      {isWABarrierSubmitted && (
        <Row justify="center" className="mb-12">
          <Col span={12}>
            <SCButton
              className="min-w-32 !bg-[#D4D5D6] font-bold text-primary sm:w-[160px]"
              scType={SC_BUTTON_TYPE.UPLOADBUTTON}
              onClick={handlePrev}
            >
              {t("wfa.actions.back")}
            </SCButton>
          </Col>
          <Col span={12} className="flex justify-end">
            <SCButton className="min-w-32" onClick={handleNext}>
              {t("wfa.actions.primary")}
            </SCButton>
          </Col>
        </Row>
      )}
    </>
  );
};

export default BarrierActionMobileView;
