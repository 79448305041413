import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { RootState } from "../../../../store";
import { useGetEntityByKey } from "../../Entity/hooks/entity.hook";

function useHasUserEditEntityPermissions() {
  const { id } = useParams();
  const { userId } = useSelector((state: RootState) => state.userDetail);

  const { data: entityDetails, isLoading } = useGetEntityByKey({
    entityRecordKey: id ?? "",
  });

  let hasUserEditEntityPermission = false;
  if (
    entityDetails?.result?.userPermission[0]?.userIds.includes(
      userId.toString()
    )
  ) {
    hasUserEditEntityPermission = true;
  }

  return {
    hasUserEditEntityPermission,
    pendingStatus: isLoading,
  };
}

export default useHasUserEditEntityPermissions;
