import { Table } from "antd";
import type { TableColumnsType } from "antd";
import React from "react";

import {
  DataType,
  EthnicityType,
  EthnicityTypeEnum,
  GenderType,
  GenderTypeEnum,
  renderRaceData,
} from "../../../../../helpers/helper";
import { OCCUPATION_LEVELS } from "../../../../../services/type.service";

const AnalysisFindingsTable: React.FC<{
  findingDetailedData: DataType[];
  occupationKey: OCCUPATION_LEVELS;
  isLoading: boolean;
}> = ({ findingDetailedData, occupationKey, isLoading }) => {
  const columns: TableColumnsType<DataType> = [
    {
      title: occupationKey,
      align: "center",
      dataIndex: "name",
      key: "name",
      width: 500,
    },
    {
      title: "Male",
      align: "center",
      children: [
        {
          title: "A",
          align: "center",
          dataIndex: "male",
          key: "male",
          render: (val: EthnicityType, ogVal) =>
            renderRaceData(val, ogVal, EthnicityTypeEnum.african, isLoading),
        },
        {
          title: "C",
          align: "center",
          dataIndex: "male",
          key: "male",
          render: (val: EthnicityType, ogVal) =>
            renderRaceData(val, ogVal, EthnicityTypeEnum.colored, isLoading),
        },
        {
          title: "I",
          align: "center",
          dataIndex: "male",
          key: "male",
          render: (val: EthnicityType, ogVal) =>
            renderRaceData(val, ogVal, EthnicityTypeEnum.Indian, isLoading),
        },
        {
          title: "W",
          align: "center",
          dataIndex: "male",
          key: "male",
          render: (val: EthnicityType, ogVal) =>
            renderRaceData(val, ogVal, EthnicityTypeEnum.white, isLoading),
        },
      ],
    },
    {
      title: "Female",
      align: "center",
      children: [
        {
          title: "A",
          align: "center",
          dataIndex: "female",
          key: "female",
          render: (val: EthnicityType, ogVal) =>
            renderRaceData(val, ogVal, EthnicityTypeEnum.african, isLoading),
        },
        {
          title: "C",
          align: "center",
          dataIndex: "female",
          key: "female",
          render: (val: EthnicityType, ogVal) =>
            renderRaceData(val, ogVal, EthnicityTypeEnum.colored, isLoading),
        },
        {
          title: "I",
          align: "center",
          dataIndex: "female",
          key: "female",
          render: (val: EthnicityType, ogVal) =>
            renderRaceData(val, ogVal, EthnicityTypeEnum.Indian, isLoading),
        },
        {
          title: "W",
          align: "center",
          dataIndex: "female",
          key: "female",
          render: (val: EthnicityType, ogVal) =>
            renderRaceData(val, ogVal, EthnicityTypeEnum.white, isLoading),
        },
      ],
    },
    {
      title: "Foreign Nationals",
      align: "center",
      children: [
        {
          title: "Male",
          align: "center",
          dataIndex: "foreignNational",
          key: "foreignNational",
          render: (val: GenderType, ogVal) =>
            renderRaceData(val, ogVal, GenderTypeEnum.male, isLoading),
        },
        {
          title: "Female",
          align: "center",
          dataIndex: "foreignNational",
          key: "foreignNational",
          render: (val: GenderType, ogVal) =>
            renderRaceData(val, ogVal, GenderTypeEnum.female, isLoading),
        },
      ],
    },
    {
      title: "Total",
      align: "center",
      dataIndex: "total",
      key: "total",
      render: (val, ogVal) => {
        if (isLoading || val == null) return null; // Render nothing
        return ogVal?.name === "Over/Underachieved %" ? `${val}%` : val;
      },
    },
  ];

  return (
    <Table<DataType>
      columns={columns}
      dataSource={findingDetailedData}
      bordered
      size="middle"
      className="mb-5"
      rowClassName="text-center"
      pagination={false}
      loading={isLoading}
      scroll={{
        x: 1000,
      }}
    />
  );
};

export default AnalysisFindingsTable;
