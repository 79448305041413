import { GetOccupationLevelStrategyAnalysisRes } from "../services/type.service";

export interface EthnicityType {
  [key: string]: number | undefined;

  african?: number; // value can be between 0.00% to 100.00% and this can also be in negative
  colored?: number; // value can be between 0.00% to 100.00% and this can also be in negative
  Indian?: number; // value can be between 0.00% to 100.00% and this can also be in negative
  white?: number; // value can be between 0.00% to 100.00% and this can also be in negative
}

export enum EthnicityTypeEnum {
  african = "african",
  colored = "colored",
  Indian = "Indian",
  white = "white",
  total="total"
}

export interface GenderType {
  [key: string]: number | undefined;

  male?: number; // value can be between 0.00% to 100.00% and this can also be in negative
  female?: number; // value can be between 0.00% to 100.00% and this can also be in negative
}

export enum GenderTypeEnum {
  male = "male",
  female = "female",
}

export interface DataType {
  key: React.Key;
  entityWorkForceAnalysisStrategiesSelectionDetailKey?: string;
  name?: string;
  male?: EthnicityType;
  female?: EthnicityType;
  foreignNational?: GenderType;
  total?: number; // value can be between 0.00% to 100.00% and this can also be in negative
}

export const renderRaceData = (
  val: EthnicityType | GenderType,
  ogVal: DataType,
  raceKey: EthnicityTypeEnum | GenderTypeEnum,
  isLoading: boolean
) => {
  if (isLoading || !val || !val[raceKey] == null) {
    return null; // Render nothing
  }
  return ogVal?.name === "Over/Underachieved %"
    ? `${val[raceKey]}%`
    : val[raceKey];
};

export const renderDataWithCondition = (
  val: EthnicityType,
  key: string,
  isLoading: boolean
) => {
  if (isLoading || !val || val[key] == null) {
    return null; // Render nothing
  }
  return `${val[key]}%`;
};

// Group by occupationName
export const groupedByOccupation = (
  data: GetOccupationLevelStrategyAnalysisRes[]
) =>
  data.reduce(
    (acc, record) => {
      const { occupationName } = record;
      if (!acc[occupationName]) {
        acc[occupationName] = [];
      }
      acc[occupationName].push(record);
      return acc;
    },
    {} as Record<string, GetOccupationLevelStrategyAnalysisRes[]>
  );
export const processWorkForceData = (allCombinedAnalysisData: DataType[]) => {
  return allCombinedAnalysisData?.length
    ? allCombinedAnalysisData?.map((item: DataType) => {
        const isActual = item.name === "Actual";
        const isCalculatedPercentage = item.name === "%";
        const isOverUnderRepresentedCount =
          item.name === "Over/Underachieved headcount";
        const isPriority = item.name === "Priority";

        return {
          entityWorkForceAnalysisStrategiesSelectionDetailKey:
            item.entityWorkForceAnalysisStrategiesSelectionDetailKey,
          africanMale: item.male?.african || 0,
          coloredMale: item.male?.colored || 0,
          indianMale: item.male?.Indian || 0,
          whiteMale: item.male?.white || 0,
          foreignNationalMale: item.foreignNational?.male || 0,
          africanFemale: item.female?.african || 0,
          coloredFemale: item.female?.colored || 0,
          indianFemale: item.female?.Indian || 0,
          whiteFemale: item.female?.white || 0,
          foreignNationalFemale: item.foreignNational?.female || 0,
          isActual: isActual,
          isCalculatedPercentage: isCalculatedPercentage,
          isOverUnderRepresentedCount: isOverUnderRepresentedCount,
          isPriority: isPriority,
        };
      })
    : [];
};

export const neededKey = [
  "Actual",
  "%",
  "Over/Underachieved headcount",
  "Priority",
];

export type ItemHelper = {
  name: string;
  val: number;
};

export const assignPriorities = (
  items: ItemHelper[]
): Record<string, number> => {
  // Create an array of objects to hold the original item and its index
  const indexedItems = items.map((item, index) => ({ ...item, index }));

  // Sort the array: negatives first (more negative gets higher priority), then positives, and zeros last
  indexedItems.sort((a, b) => {
    if (a.val < 0 && b.val < 0) return a.val - b.val; // Sort negatives ascending
    if (a.val < 0) return -1; // Negatives come first
    if (b.val < 0) return 1; // Negatives come first
    if (a.val === 0) return 1; // Move zeros to the end
    if (b.val === 0) return -1; // Move zeros to the end
    return b.val - a.val; // Sort positives descending
  });

  // Assign priorities
  const priorities: Record<string, number> = {};
  let currentPriority = 1; // Start priority level

  for (let i = 0; i < indexedItems.length; i++) {
    const { name, val } = indexedItems[i];

    if (val === 0) {
      priorities[name] = 0; // Assign priority 0 for 0 values
    } else if (i > 0 && val === indexedItems[i - 1].val) {
      priorities[name] = priorities[indexedItems[i - 1].name]; // Same priority for equal values
    } else {
      priorities[name] = currentPriority++; // Increment priority for unique values
    }
  }

  return priorities;
};
