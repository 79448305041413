import { Col, Row, TableProps, Typography } from "antd";
import { size } from "lodash";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

import { ReactComponent as DownArrow } from "../../../../../../../../assets/images/svg/down-arrow-black.svg";
import { NextStateProps } from "../../../../../../../../hooks/useStepsNav";
import SCButton from "../../../../../../../core/component/ui/SCButton";
import SCTable from "../../../../../../../core/component/ui/SCTable";
import { ROUTES_CONST } from "../../../../../../../core/models/constants/core.constants";
import { useFetchReportAnalysis } from "../../hooks/WorkforceAnalysis.hooks";
import { FetchReportAnalysisResponse } from "../../services/wfa.services";

const isStringNotEmpty = (string: string | undefined) => {
  return size(string) > 0;
};

type IDottedTextRow = {
  title: string;
  value?: string;
};

const DottedTextRow = ({ title, value }: IDottedTextRow) => {
  return (
    <Row gutter={[16, 16]} className="mb-5 gap-x-5">
      <Col span={24}>
        <Typography.Text className="flex w-full select-none gap-x-3 text-lg tracking-wide">
          <div className="whitespace-nowrap">{title} :</div>
          <div className="flex w-full">
            <strong className="whitespace-nowrap">{value}</strong>
            <div className="grow border-b border-dotted border-black" />
          </div>
        </Typography.Text>
      </Col>
    </Row>
  );
};

type ITablePropsColumns = {
  key: string;
  first: JSX.Element;
  second: JSX.Element;
};

const EEAnalysisReport = ({ next }: Readonly<NextStateProps>) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const { data: reportData, isLoading } = useFetchReportAnalysis({
    entityId: id ?? "",
  });

  const [reportDataResult, setReportDataResult] = useState<
    FetchReportAnalysisResponse | undefined
  >(undefined);

  const [isDetailsEmpty, setIsDetailsEmpty] = useState<boolean>(true);

  useEffect(() => {
    if (reportData?.result) {
      setReportDataResult(reportData?.result);
    }
  }, [reportData]);

  const {
    tradeName,
    dtiRegistrationNumber,
    paysrsNumber,
    uifReferenceNumber,
    email,
    telephoneNumber,
    setaClassification,
    industrySubSector,
    physicalProvince,
    postalAddress,
    physicalAddress,
    postalCode,
    ceoName,
  } = reportDataResult || {};

  useEffect(() => {
    if (
      size(tradeName) > 0 &&
      size(dtiRegistrationNumber) > 0 &&
      size(paysrsNumber) > 0 &&
      size(uifReferenceNumber?.toString()) > 0 &&
      size(email) > 0 &&
      size(telephoneNumber) > 0 &&
      size(setaClassification) > 0 &&
      size(industrySubSector) > 0 &&
      size(physicalProvince) > 0 &&
      size(postalAddress) > 0 &&
      size(physicalAddress) > 0 &&
      size(postalCode?.toString()) > 0 &&
      size(ceoName) > 0
    ) {
      setIsDetailsEmpty(false);
    } else {
      setIsDetailsEmpty(true);
    }
  }, [reportDataResult]);

  const columns: TableProps<ITablePropsColumns>["columns"] = [
    {
      title: (
        <div className="relative flex">
          {t("wfa.section_1.tableCol1")}
          <DownArrow className="absolute left-1/2 right-1/2 top-[-13px] z-30 flex -translate-x-1/2 translate-y-10 transform items-center justify-center" />
        </div>
      ),
      dataIndex: "first",
      key: "first",
      width: "300px",
    },
    {
      title: t("wfa.section_1.tableCol2"),
      dataIndex: "second",
      key: "second",
    },
  ];

  const rows: ITablePropsColumns[] = [
    {
      key: "1",
      first: (
        <Typography.Text className="mt-20 flex w-full min-w-[250px] flex-col">
          <strong>{t("wfa.section_1.departmentOfLabourTitleCol1")}</strong>
          <br />
          <div>{reportDataResult?.reportText}</div>
        </Typography.Text>
      ),
      second: (
        <>
          <Row>
            <Col span={24} className="text-center">
              <Typography.Text>
                <strong>{t("wfa.section_1.departmentOfLabourTitle")}</strong>
                <br /> ({t("wfa.section_1.departmentOfLabourTitle1")})
              </Typography.Text>
            </Col>
          </Row>

          <Row className="my-5">
            <Col span={24}>
              <Typography.Text>
                <strong>{t("wfa.section_1.form.employerDetails")}</strong>
              </Typography.Text>
            </Col>
          </Row>

          <DottedTextRow
            title={t("wfa.section_1.form.tradeName")}
            value={tradeName}
          />
          <DottedTextRow
            title={t("wfa.section_1.form.dtiName")}
            value={dtiRegistrationNumber}
          />
          <DottedTextRow
            title={t("wfa.section_1.form.payeNo")}
            value={paysrsNumber}
          />
          <DottedTextRow
            title={t("wfa.section_1.form.refNo")}
            value={uifReferenceNumber}
          />
          <DottedTextRow
            title={t("wfa.section_1.form.setaClassification")}
            value={setaClassification}
          />
          <DottedTextRow
            title={t("wfa.section_1.form.sector")}
            value={industrySubSector}
          />
          <DottedTextRow
            title={t("wfa.section_1.form.province")}
            value={physicalProvince}
          />
          <DottedTextRow
            title={t("wfa.section_1.form.telNo")}
            value={telephoneNumber}
          />
          <DottedTextRow
            title={t("wfa.section_1.form.address")}
            value={postalAddress}
          />
          <DottedTextRow
            title={t("wfa.section_1.form.physicalAddress")}
            value={physicalAddress}
          />
          <DottedTextRow
            title={t("wfa.section_1.form.postalCode")}
            value={postalCode}
          />
          <DottedTextRow
            title={t("wfa.section_1.form.ceoName")}
            value={ceoName}
          />
          <DottedTextRow title={t("wfa.section_1.form.email")} value={email} />
        </>
      ),
    },
  ];

  const handleNext = () => {
    next();
  };

  return (
    <>
      <Row justify="center" className="!mt-8">
        <Col span={20} className="text-center">
          <Typography.Title
            className="!mb-16 text-center md:!text-primary custom-xss:mb-16"
            level={2}
          >
            {t("wfa.title")}
          </Typography.Title>
        </Col>
      </Row>

      <Row justify="center" className="">
        <Col xs={24} className="mb-8 grid place-items-center overflow-auto">
          <SCTable
            columns={columns}
            dataSource={rows}
            rowClassName={() => "align-top"}
            loading={isLoading}
            className="min-w-full custom-xlll:min-w-[1000px]"
          />
        </Col>
      </Row>

      {isDetailsEmpty && (
        <Row className="mb-3">
          <Col span={24} className="flex justify-end">
            <p className="text-customRed">
              <Trans
                t={t}
                i18nKey="wfa.section_1.shouldFilled"
                components={{
                  strong: <strong />,
                  a: (
                    <Link
                      to={`${ROUTES_CONST.HOME}${ROUTES_CONST.EMPLOYMENT_EQUITY}/${id}/${ROUTES_CONST.COMPANYPROFILE}`}
                    />
                  ),
                }}
              />
            </p>
          </Col>
        </Row>
      )}

      <Row justify="center" className="mb-12">
        <Col span={12} />
        <Col span={12} className="flex justify-end">
          <SCButton
            className="min-w-32"
            onClick={handleNext}
            disabled={isDetailsEmpty}
          >
            {t("wfa.section_1.btnNext")}
          </SCButton>
        </Col>
      </Row>
    </>
  );
};

export default EEAnalysisReport;
