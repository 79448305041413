type Props = {
  width?: number;
  height?: number;
  className?: string;
};

const ArrowSeparaterIcon = ({ height = 24, width = 24, className }: Props) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 17 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1L16 37L1 72.5"
        stroke="#C1C1C1"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowSeparaterIcon;
