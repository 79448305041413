export enum OCCUPATION_LEVELS {
  TOP_MANAGEMENT = "Top Management",
  SENIOR_MANAGEMENT = "Senior Management",
  MIDDLE_MANAGEMENT = "Middle Management",
  JUNIOR_MANAGEMENT = "Junior Management",
  SEMI_SKILLED = "Semi-Skilled",
  UNSKILLED = "Unskilled",
}

export interface IEntityEAPMasterDtos {
  entityEAPMasterId: number;
  entityEAPMasterKey: string;
  startDate: string;
  endDate: string;
  recordStatusId: number;
  africanMale: number;
  coloredMale: number;
  indianMale: number;
  whiteMale: number;
  foreignNationalMale: number;
  africanFemale: number;
  coloredFemale: number;
  indianFemale: number;
  whiteFemale: number;
  foreignNationalFemale: number;
  total: number;
}

export type IAnalysisStrategies = {
  entityWorkForceAnalysisStrategiesSelectionDetailKey: string;
  africanMale: number;
  coloredMale: number;
  indianMale: number;
  whiteMale: number;
  foriegnNationalMale: number;
  africanFemale: number;
  coloredFemale: number;
  indianFemale: number;
  whiteFemale: number;
  foriegnNationalFemale: number;
  isActual: boolean;
  isCalculatedPercentage: boolean;
  isOverUnderRepresentedCount: boolean;
  total: number;
};

export const neededDataInPriority = [
  "africanFemale",
  "coloredFemale",
  "indianFemale",
  "whiteFemale",
  "africanMale",
  "coloredMale",
  "indianMale",
  "whiteMale",
];

export type GetOccupationLevelStrategyAnalysisRes = {
  workForceAnalysisStrategiesSelectionDtos: IAnalysisStrategies[];
  entityEAPMasterDtos: IEntityEAPMasterDtos;
  entityWorkForceAnalysisStrategiesSelectionKey: string;
  entityWorkForceAnalysisStrategiesSelectionId: 0;
  snapshotDate: string;
  isStrategyApplied: boolean;
  strategyNarratives: string;
  strategyNarrativesAction: string;
  entityId: number;
  eapMasterId: number;
  occupationId: number;
  occupationName: string;
};
