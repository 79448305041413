import { Checkbox, Col, Flex, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { FaCheck } from "react-icons/fa";

import EEYellowTickBanner from "../../../../../../../../../core/component/ui/EEYellowTickBanner/EEYellowTickBanner";
import SCButton from "../../../../../../../../../core/component/ui/SCButton";
import { SC_BUTTON_TYPE } from "../../../../../../../../../core/models/constants/core.constants";
import { SkeletonCommon } from "../../../../containers/WorkforceAnalysisContainer/WorkforceAnalysisContainer";
import useStrategiesSelection, {
  NarretiveAndActionDataType,
  actionListDataType,
} from "../../../../hooks/useStrategiesSelection";
import { GetOccupationLevelStrategyAnalysisRes } from "../../../../services/type.service";
import {
  strategyNarrativeListResponse,
  strategyNarretiveActionsResponse,
} from "../../../../services/wfa.services";
import styles from "../../../SCCheckboxButton//SCCheckboxButton.module.css";
import SCCheckboxButtonHeading from "../../../SCCheckboxButton/SCCheckboxButtonHeading";

export type EEStrategiesSelectionProps = {
  allData: GetOccupationLevelStrategyAnalysisRes;
  saveStrategySelectionLocallyHandler: Function;
  narretiveData: strategyNarrativeListResponse[];
  strategyNarrativeActionData: strategyNarretiveActionsResponse[];
  handlePrev: () => void;
  postWorkforceAnalysisStrategySelectionLoader: boolean;
};

const EEStrategiesSelection = (props: EEStrategiesSelectionProps) => {
  const {
    allData,
    saveStrategySelectionLocallyHandler,
    narretiveData,
    strategyNarrativeActionData,
    handlePrev,
    postWorkforceAnalysisStrategySelectionLoader,
  } = props;
  const { t } = useTranslation();

  const {
    isLoading,
    isWAStrategiesSubmitted,
    showConfirmbtn,

    setSelectedItem,
    narretiveAndActionData,
    actionsData,
    isStrategiesApplicable,
    setStrategiesApplicable,
    getSelectedElementsIdArray,
  } = useStrategiesSelection({
    narretiveData,
    strategyNarrativeActionData,
    allData,
  });

  const saveStrategySelectionLocally = () => {
    const { narretiveIdsArray, barrierNaritiveIdsArray } =
      getSelectedElementsIdArray();

    saveStrategySelectionLocallyHandler(
      narretiveIdsArray,
      barrierNaritiveIdsArray
    );
  };

  const renderBox = (
    item: NarretiveAndActionDataType | actionListDataType,
    showTick: boolean,
    key: string
  ) => {
    let className = styles.wrapper;

    if (item.isTicked || item.isSelected) {
      className = `${className} ${styles.wrapperActive}`;
    }

    return (
      <div key={`item-${key}`} className="relative">
        <Flex
          gap={10}
          align="flex-start"
          justify="space-between"
          className={className}
        >
          <div
            className={styles.iconWrapper}
            onClick={() =>
              setSelectedItem({
                ...item,
                timeStampEpoch: Date.now(),
                isSelected: !item.isSelected,
              })
            }
            aria-hidden
          >
            {showTick && (
              <div className={styles.icon}>
                {item.isTicked && <FaCheck size={25} color="var(--primary)" />}
              </div>
            )}

            <Typography.Text>{item.content}</Typography.Text>
          </div>
        </Flex>
      </div>
    );
  };

  return (
    <>
      <div className="m-8 !ml-0 !w-full">
        <span className="text-[20px] font-semibold text-primary">
          {t("wfa.OLA.strategiesSelection")}
        </span>
        <div className="my-5">
          <Checkbox
            checked={isStrategiesApplicable}
            onChange={(e) => setStrategiesApplicable(e.target.checked)}
          >
            <span className="font-[20px] text-primary">
              {t("wfa.OLA.strategiesApplicable")}
            </span>
          </Checkbox>
        </div>
        {isStrategiesApplicable ? (
          <Row
            gutter={[16, 16]}
            justify="space-between"
            align="top"
            className="mb-16 w-full rounded-lg border border-gray p-5"
          >
            <Col xs={24} sm={12} md={12} lg={12}>
              {isLoading ? (
                <SkeletonCommon />
              ) : (
                <>
                  <EEYellowTickBanner title={t("wfa.highlightBox.step_1")} />
                  <div className="grid gap-y-5">
                    <SCCheckboxButtonHeading title={"Categories"} />
                    {narretiveAndActionData.map(
                      (item: NarretiveAndActionDataType) => {
                        return renderBox(
                          item,
                          true,
                          `${item.strategyId}-strategyId`
                        );
                      }
                    )}
                  </div>
                </>
              )}
            </Col>

            <Col xs={24} sm={12} md={12} lg={12}>
              {isLoading ? (
                <SkeletonCommon />
              ) : (
                <>
                  <EEYellowTickBanner title={t("wfa.highlightBox.step_3")} />

                  <div className="grid gap-y-5">
                    <SCCheckboxButtonHeading
                      title={t("wfa.highlightBox_step3.step_1.sub_heading3")}
                    />

                    {actionsData?.map((item: actionListDataType) => {
                      return renderBox(
                        item,
                        false,
                        `${item.strategyActionExampleId}-strategyActionExampleId`
                      );
                    })}
                  </div>
                </>
              )}

              {showConfirmbtn && !isWAStrategiesSubmitted ? (
                <SCButton
                  type="primary"
                  className="primary-btn mt-[20px] w-full font-bold custom-sm:h-[40px]"
                  onClick={() => saveStrategySelectionLocally()}
                >
                  {t("wfa.actions.confirm")}
                </SCButton>
              ) : null}
            </Col>
          </Row>
        ) : null}
      </div>

      <Row justify="center" className="mb-12">
        <Col span={24} className="flex justify-start">
          <SCButton
            className="min-w-32 !bg-[#D4D5D6] font-bold text-primary sm:w-[160px]"
            scType={SC_BUTTON_TYPE.UPLOADBUTTON}
            onClick={handlePrev}
          >
            {t("wfa.actions.back")}
          </SCButton>
        </Col>
      </Row>
    </>
  );
};

export default EEStrategiesSelection;
