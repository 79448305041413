import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export const initialState: any = {};

export const EE_CompanyDetailsSlice = createSlice({
  name: "ee_companyDetails",
  initialState,
  reducers: {
    setEECompanyDetailsState: (state, action: PayloadAction<any>) => {
      state.tradeName = action.payload.tradeName;
      state.dtiRegistrationName = action.payload.dtiRegistrationName;
      state.dtiRegistrationNumber = action.payload.dtiRegistrationNumber;
      state.sdlNumber = action.payload.sdlNumber;
      state.taxNumber = action.payload.taxNumber;
      state.uifReferenceNumber = action.payload.uifReferenceNumber;
      state.employmentEquityReferenceNumber =
        action.payload.employmentEquityReferenceNumber;
      state.setaClassification = action.payload.setaClassification;
      state.sicCode = action.payload.sicCode;
      state.industry = action.payload.industry;
      state.industrySubSector = action.payload.industrySubSector;
      state.bargainingCouncil = action.payload.bargainingCouncil;
      state.businessType = action.payload.businessType;
      state.isYourCompanyAndOrganOfState =
        action.payload.isYourCompanyAndOrganOfState;
      state.isYourCompanyPartOfHoldingCompany =
        action.payload.isYourCompanyPartOfHoldingCompany;
      state.holdingCompanyName = action.payload.holdingCompanyName;
      state.holdingCompanySDLNumber = action.payload.holdingCompanySDLNumber;
      state.telephoneNumber = action.payload.telephoneNumber;
      state.email = action.payload.email;
      state.postalAddress = action.payload.postalAddress;
      state.postalCode = action.payload.postalCode;
      state.postalCity = action.payload.postalCity;
      state.postalProvince = action.payload.postalProvince;
      state.postalCountry = action.payload.postalCountry;
      state.physicalAddress = action.payload.physicalAddress;
      state.physicalCode = action.payload.physicalCode;
      state.physicalCity = action.payload.physicalCity;
      state.physicalProvince = action.payload.physicalProvince;
      state.sameAsPhysical = action.payload.sameAsPhysical;
      state.physicalCountry = action.payload.physicalCountry;
      state.financialDetail = action.payload.financialDetail;
      state.accountHolder = action.payload.accountHolder;
      state.bankName = action.payload.bankName;
      state.accountNumber = action.payload.accountNumber;
      state.branchName = action.payload.branchName;
      state.branchCode = action.payload.branchCode;
      state.numberOfPermanentEmployees =
        action.payload.numberOfPermanentEmployees;
      state.numberOfTemporaryEmployees =
        action.payload.numberOfTemporaryEmployees;
      state.totalNumberOfEmployees = action.payload.totalNumberOfEmployees;
      state.financialManagerNameAndSurname =
        action.payload.financialManagerNameAndSurname;
      state.financialManagerDesignation =
        action.payload.financialManagerDesignation;
      state.financialManagerContactNumber =
        action.payload.financialManagerContactNumber;
      state.financialManagerEmailAddress =
        action.payload.financialManagerEmailAddress;
      state.companyContactPersonNameAndSurname =
        action.payload.companyContactPersonNameAndSurname;
      state.companyContactPersonDesignation =
        action.payload.companyContactPersonDesignation;
      state.companyContactPersonContactNumber =
        action.payload.companyContactPersonContactNumber;
      state.companyContactPersonEmailAddress =
        action.payload.companyContactPersonEmailAddress;
      state.employeeEquitySeniorManagerNameAndSurname =
        action.payload.employeeEquitySeniorManagerNameAndSurname;
      state.employeeEquitySeniorManagerDesignation =
        action.payload.employeeEquitySeniorManagerDesignation;
      state.employeeEquitySeniorManagerContactNumber =
        action.payload.employeeEquitySeniorManagerContactNumber;
      state.employeeEquitySeniorManagerEmailAddress =
        action.payload.employeeEquitySeniorManagerEmailAddress;
      state.sdfNameAndSurname = action.payload.sdfNameAndSurname;
      state.sdfDesignation = action.payload.sdfDesignation;
      state.sdfContact = action.payload.sdfContact;
      state.sdfEmail = action.payload.sdfEmail;
      state.internalOrExternalSDF = action.payload.internalOrExternalSDF;
      state.sdfServiceProviderName = action.payload.sdfServiceProviderName;
      state.employeeRepresentativeNameAndSurname =
        action.payload.employeeRepresentativeNameAndSurname;
      state.employeeRepresentativeDesignation =
        action.payload.employeeRepresentativeDesignation;
      state.employeeRepresentativeContactNumber =
        action.payload.employeeRepresentativeContactNumber;
      state.employeeRepresentativeEmail =
        action.payload.employeeRepresentativeEmail;
      state.unionRepresentativeNameAndSurname =
        action.payload.unionRepresentativeNameAndSurname;
      state.unionRepresentativeDesignation =
        action.payload.unionRepresentativeDesignation;
      state.unionRepresentativeContactNumber =
        action.payload.unionRepresentativeContactNumber;
      state.unionRepresentativeEmail = action.payload.unionRepresentativeEmail;
      state.clientId = action.payload.clientId;
      state.userId = action.payload.userId;
      state.dtiRegistrationCertificateDocument =
        action.payload.dtiRegistrationCertificateDocument;
      state.taxClearanceCertificateDocument =
        action.payload.taxClearanceCertificateDocument;
      state.confirmationOfBankingDetailsDocument =
        action.payload.confirmationOfBankingDetailsDocument;
      state.sdfLetterOfAppointmentDocument =
        action.payload.sdfLetterOfAppointmentDocument;
      state.skillsAndEquityCommitteeMeetingAgendaAndMinutesDocument =
        action.payload.skillsAndEquityCommitteeMeetingAgendaAndMinutesDocument;
      state.emP201Document = action.payload.emP201Document;
      state.paysrsNumber = action.payload.paysrsNumber;
      state.unionName = action.payload.unionName;
      state.workForceProfileUnionEmployeeId =
        action.payload.workForceProfileUnionEmployeeId || 0;
      state.workForceProfileCEOEmployeeId =
        action.payload.workForceProfileCEOEmployeeId || 0;
      state.workForceProfileSDFEmployeeId =
        action.payload.workForceProfileSDFEmployeeId || 0;
      state.workForceProfileSuperAdminEmployeeId =
        action.payload.workForceProfileSuperAdminEmployeeId || 0;
      state.companyProfileDocuments = action.payload.companyProfileDocuments;
      state.industryAmendmentsId = action.payload.industryAmendmentsId;
      state.snapShotDate = action.payload.snapShotDate;
      state.eePlanStartDate = action.payload.eePlanStartDate;
      state.eePlanDuration = action.payload.eePlanDuration;
      state.industryId = action.payload.industryId;
      state.employeeEquityCompanyProfileKey =
        action.payload.employeeEquityCompanyProfileKey;
      state.isPayrollAdded = action.payload.isPayrollAdded;
      state.isDurationExpired = action.payload.isDurationExpired;
    },
  },
});

export const { setEECompanyDetailsState } = EE_CompanyDetailsSlice.actions;

export default EE_CompanyDetailsSlice.reducer;
