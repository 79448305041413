import { MutateOptions, useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { getJobTitleListService } from "../../../../../../auth/services/CommonService";
import { GenericResponse } from "../../../../../Teams/service/teamsServices";
import {
  FetchReportAnalysisPayload,
  GetBarriersResponse,
  GetOccupationLevelStrategyAnalysis,
  GetOccupationLevelStrategyAnalysisPayload,
  GetStrategyNarretivePayload,
  GetWorkforceAnalysisPayload,
  PostWorkforceAnalysisPayload,
  fetchBarriers,
  fetchReportAnalysis,
  fetchStrategyNarrative,
  fetchStrategyNarrativeActionExamples,
  getSubmittedWABarriersData,
  postWorkforceAnalysis,
  postWorkforceAnalysisStrategySelection,
  postWorkforceAnalysisStrategySelectionPayload,
} from "../services/wfa.services";

export const GET_OCCUPATION_LEVEL_STRATEGY_ANALYSIS_KEY =
  "GET_OCCUPATION_LEVEL_STRATEGY_ANALYSIS_KEY";

export const useGetOccupationLevelStrategyAnalysis = (
  params: GetOccupationLevelStrategyAnalysisPayload
) => {
  const resp = useQuery({
    queryKey: [GET_OCCUPATION_LEVEL_STRATEGY_ANALYSIS_KEY, params],
    queryFn: async () => {
      const response = await GetOccupationLevelStrategyAnalysis(params);
      return response;
    },
    enabled: !!params.entityRecordKey,
  });

  return resp;
};

export const FETCH_STRATEGY_NARRATIVE_KEY = "FETCH_STRATEGY_NARRATIVE_KEY";

export const useFetchStrategyNarrative = (
  params: GetStrategyNarretivePayload
) => {
  const resp = useQuery({
    queryKey: [FETCH_STRATEGY_NARRATIVE_KEY, params],
    queryFn: async () => {
      const response = await fetchStrategyNarrative(params);
      return response;
    },
    enabled: !!params.entityRecordKey,
  });

  return resp;
};

export const FETCH_STRATEGY_NARRATIVE_ACTION_EXAMPLES_KEY =
  "FETCH_STRATEGY_NARRATIVE_ACTION_EXAMPLES_KEY";

export const useFetchStrategyNarrativeActionExamples = (
  params: GetWorkforceAnalysisPayload
) => {
  const resp = useQuery({
    queryKey: [FETCH_STRATEGY_NARRATIVE_ACTION_EXAMPLES_KEY, params],
    queryFn: async () => {
      const response = await fetchStrategyNarrativeActionExamples(params);
      return response;
    },
    enabled: !!params.entityRecordKey,
  });

  return resp;
};

export const FETCH_WORKFORCE_ANALYSIS_KEY = "FETCH_WORKFORCE_ANALYSIS_KEY";

export const useGetSubmittedWABarriersData = (
  params: GetWorkforceAnalysisPayload
) => {
  const resp = useQuery({
    queryKey: [FETCH_WORKFORCE_ANALYSIS_KEY, params],
    queryFn: async () => {
      const response = await getSubmittedWABarriersData(params);
      return response;
    },
    enabled: !!params.entityRecordKey,
  });

  return resp;
};

export const FETCH_JOB_TITLE_LIST_KEY = "FETCH_JOB_TITLE_LIST_KEY";

export type IJobTitleList = {
  jobTitleID: number;
  jobTitleKey: string;
  jobTitleName: string;
  sectorID: number;
  ofoCodeID: number;
  created_Date: string;
  sector: {
    sectorId: number;
    sectorKey: string;
    createdDate: string;
    sectorDescription: string;
    recordStatusId: number;
  };
  ofoCode: {
    ofoCodeId: number;
    ofoCodeKey: string;
    createdDate: string;
    ofoCode: null;
    majorGroupCode: null;
    subMajorGroupCode: null;
    minorGroupCode: null;
    unitGroupCode: null;
    occupationCode: null;
    description: null;
    recordStatusId: number;
  };
  isApproved: true;
};

export const useGetJobTitleListService = () => {
  const resp = useQuery({
    queryKey: [FETCH_JOB_TITLE_LIST_KEY],
    queryFn: async () => {
      const response: {
        data: GenericResponse<IJobTitleList[]>;
      } = await getJobTitleListService();

      return response?.data?.result || [];
    },
  });

  return resp;
};

export const usePostWorkforceAnalysis = (
  options?: MutateOptions<
    GenericResponse<void>,
    AxiosError,
    PostWorkforceAnalysisPayload
  >
) => {
  return useMutation({
    mutationFn: postWorkforceAnalysis,
    ...options,
    onSuccess: (data, vars, context) => {
      if (options?.onSuccess) {
        options.onSuccess(data, vars, context);
      }
    },
    onError: (error, vars, context) => {
      if (options?.onError) {
        options.onError(error, vars, context);
      }
    },
  });
};

export const FETCH_BARRIERS_KEY = "FETCH_BARRIERS_KEY";

export const useFetchBarriers = () => {
  const resp = useQuery({
    queryKey: [FETCH_BARRIERS_KEY],
    queryFn: async () => {
      const response = await fetchBarriers();
      return response?.result;
    },
  });

  return resp;
};

export const usePostWorkforceAnalysisStrategySelection = (
  options?: MutateOptions<
    GenericResponse<void>,
    AxiosError,
    postWorkforceAnalysisStrategySelectionPayload
  >
) => {
  return useMutation({
    mutationFn: postWorkforceAnalysisStrategySelection,
    ...options,
    onSuccess: (data, vars, context) => {
      if (options?.onSuccess) {
        options.onSuccess(data, vars, context);
      }
    },
    onError: (error, vars, context) => {
      if (options?.onError) {
        options.onError(error, vars, context);
      }
    },
  });
};

export const FETCH_REPORT_KEY = "FETCH_REPORT_KEY";
export const useFetchReportAnalysis = (params: FetchReportAnalysisPayload) => {
  const resp = useQuery({
    queryKey: [FETCH_REPORT_KEY],
    queryFn: async () => {
      const response = await fetchReportAnalysis(params);
      return response;
    },
  });

  return resp;
};
